import React from 'react';
import { Container, Text } from './styles';

interface Props {
  ids: string[];
  onClick: (id: string) => void;
  disabled?: boolean;
}

const IdList: React.FC<Props> = (props) => {
  const { ids, disabled, onClick } = props;

  return (
    <Container>
      {ids.map((id) => (
        <Text key={id} onClick={() => onClick(id)} className={disabled ? 'disabled' : ''}>
          {id}
        </Text>
      ))}
    </Container>
  );
};

export default IdList;
