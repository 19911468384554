import React from 'react';
import { create } from 'zustand';

type MapLoadersStore = {
  loaders: Record<string, string | JSX.Element>;
  addLoader: (s: { id: string; value: string | JSX.Element }) => void;
  removeLoader: (id: string) => void;
};

export const useMapLoaderStore = create<MapLoadersStore>((set) => ({
  loaders: {},
  addLoader: ({ id, value }) =>
    set((state) => {
      return { loaders: { ...state.loaders, [id]: value } };
    }),
  removeLoader: (id) =>
    set((state) => {
      delete state.loaders[id];
      return { loaders: state.loaders };
    })
}));
