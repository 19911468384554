import { Polygon } from '@components/Map/Polygon/types';
import { create } from 'zustand';

export enum PolygonKeys {
  CrossingIndex = 'crossingIndex'
}

type PolygonRecord = Record<string, Polygon>;

type PlygonStore = {
  currentPoylgonDrawer?: PolygonKeys;
  polygons: Partial<Record<PolygonKeys, Record<string, [number, number][]>>>;

  setCurrentPolygonDrawer: (key?: PolygonKeys) => void;
  addPolygon: ({ polygon, key }: { polygon: PolygonRecord; key: PolygonKeys }) => void;
  removePolygon: ({ id, key }: { id: string; key: PolygonKeys }) => void;
  resetPolygons: () => void;
};

export const usePolygonStore = create<PlygonStore>((set, get) => ({
  currentPoylgonDrawer: undefined,
  polygons: {},
  setCurrentPolygonDrawer: (currentPoylgonDrawer) => set(() => ({ currentPoylgonDrawer })),
  addPolygon: ({ polygon, key }) =>
    set((state) => ({
      polygons: {
        [key]: {
          ...(state.polygons[key] || {}),
          ...polygon
        }
      }
    })),
  removePolygon: ({ id, key }) =>
    set((state) => ({
      polygons: {
        [key]: Object.entries(state.polygons[key] || {}).reduce<PolygonRecord>(
          (acc, [key, value]) => {
            if (key !== id) acc[key] = value;
            return acc;
          },
          {}
        )
      }
    })),
  resetPolygons: (key?: PolygonKeys) =>
    set((state) => ({
      polygons: {}
    }))
}));
