import AnalyzedEntityContext from '@contexts/AnalyzedEntityContext';
import React, { useContext } from 'react';
import { StatusesDiv, NullDiv } from './styles';

const Statuses = () => {
  const { statuses, analyzedEntityList, emptyAnalyzedEntitiesIdList } =
    useContext(AnalyzedEntityContext);
  return (
    <StatusesDiv>
      {statuses.approved + statuses.rejected}/{analyzedEntityList.length}
      {emptyAnalyzedEntitiesIdList.length > 0 && (
        <NullDiv> (+{emptyAnalyzedEntitiesIdList.length} missing) </NullDiv>
      )}
    </StatusesDiv>
  );
};

export default Statuses;
