import styled from 'styled-components';
import { Position } from './types';

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
`;

export const Container = styled.div<{
  verticalPosition: Position;
  horizontalPosition: Position;
  zIndex: number;
}>`
  position: fixed;

  right: 25px;
  bottom: 25px;

  box-sizing: border-box;

  z-index: ${(props) => props.zIndex};
  display: flex;
  justify-content: ${(props) => props.horizontalPosition};
  align-items: ${(props) => props.verticalPosition};
`;
