import { AnalyzedEntity } from '@contexts/AnalyzedEntityContext';
import { useEffect, useState } from 'react';

export const useUtilityData = (analyzedEntity: AnalyzedEntity | undefined, dependecies: any[]) => {
  const [value, setValue] = useState();

  useEffect(() => {
    if (!analyzedEntity?.id) return;
    const utilityData = analyzedEntity?.geometry;
    const utilityDataJson = utilityData && JSON.parse(utilityData.replaceAll("'", '"'));
    setValue(utilityDataJson);
  }, dependecies);

  return value as any;
};
