import React from 'react';
import {
  ModalBody,
  ModalButton,
  ModalContainer,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from './styles';

interface ModalProps {
  title: string;
  text: string;
  acceptText: string;
  cancelText: string;
  onAccept: () => void;
  onCancel: () => void;
  isOpen: boolean;
}

const Modal = ({ title, text, acceptText, cancelText, onAccept, onCancel, isOpen }: ModalProps) => {
  if (!isOpen) return null;
  return (
    <ModalOverlay>
      <ModalContainer>
        <ModalHeader>
          <h2>{title}</h2>
        </ModalHeader>
        <ModalBody>
          <p>{text}</p>
        </ModalBody>
        <ModalFooter>
          <ModalButton variant="primary" onClick={onAccept}>
            {acceptText}
          </ModalButton>
          <ModalButton onClick={onCancel}>{cancelText}</ModalButton>
        </ModalFooter>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default Modal;
