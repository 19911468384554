import React from 'react';
import { Container, Text, Icon, IconWrapper } from './styles';
import CommentsIcon from '@assets/close.svg';

interface Props {
  onClosePopUp?: () => void;
}

const Header: React.FC<Props> = (props) => {
  const { onClosePopUp } = props;
  return (
    <Container>
      <IconWrapper>
        <Icon onClick={onClosePopUp} src={CommentsIcon} />
      </IconWrapper>
    </Container>
  );
};

export default Header;
