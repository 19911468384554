// GlobalStyles.js
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body, html {
    font-family: 'Montserrat';
  }
 /* Total width */
::-webkit-scrollbar {
    width: 8px;
    height: 8px; /* for horizontal scrollbar */
}

/* Background of the scrollbar */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

/* Scrollbar handle */
::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,0.2);
    border-radius: 10px;
}

/* Scrollbar handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* Corner when both horizontal and vertical scrollbars are present */
::-webkit-scrollbar-corner {
    background: #f1f1f1;
}

`;

export default GlobalStyle;
