import { SELECT_ALL, type Option } from './MultiSelect';

export const getPlatformTypeOptions = (types: string[]): Option[] => {
  types.unshift(SELECT_ALL);

  return types.map((type) => ({
    label: type,
    value: type
  }));
};
