import React from 'react';
import { FeatureCollection } from 'geojson';
import { PathOptions } from 'leaflet';
import GeoJsonWithUpdates from '@components/Map/GeoJsonWithUpdates';

interface Props {
  scanPathData?: FeatureCollection;
}

const scanPathStyle: PathOptions = {
  color: 'black',
  dashArray: '5, 5',
  weight: 1
};

const ScanPath: React.FC<Props> = (props) => {
  const { scanPathData } = props;
  if (scanPathData === undefined) {
    return null;
  }
  if (scanPathData?.features && scanPathData?.features.length === 1) {
    const color = scanPathData.features[0].properties?.color;
    return (
      <GeoJsonWithUpdates
        data={scanPathData}
        style={{ ...scanPathStyle, color: color || 'black' }}
      />
    );
  }

  if (!scanPathData?.features) {
    return null;
  }
  return (
    <>
      {scanPathData.features.map((feature, index) => (
        <GeoJsonWithUpdates
          key={index}
          data={feature}
          style={{ ...scanPathStyle, color: feature?.properties?.color || 'black' }}
        />
      ))}
    </>
  );
};

export default ScanPath;
