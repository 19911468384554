import { AnalyzedEntity } from '@contexts/AnalyzedEntityContext';
import { useEffect, useState } from 'react';

export type CrossingPipe = {
  lon: number;
  lat: number;
  crossing_index: number;
};

export const usePipesCrossings = (
  analyzedEntity: AnalyzedEntity | undefined,
  depndencies: any[]
) => {
  const [value, setValue] = useState<CrossingPipe[]>([]);

  useEffect(() => {
    if (!analyzedEntity?.pipe_crossings) return;
    const pipesCrossings = JSON.parse(analyzedEntity?.pipe_crossings || '[]');
    setValue(pipesCrossings);
  }, depndencies);

  return value;
};
