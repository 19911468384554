import { create } from 'zustand';

import type { Status } from '@graphql/generated';
import type { CrossingI } from '@pages/Main/DetailsView';

type CrossingViewStore = {
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  crossingData: Record<string, CrossingI>;
  setCrossingData: (id: string, crossingData: CrossingI) => void;
  crossingsStatus: Record<string, (Status | null)[]>;
  setCrossingStatus: (id: string, status: (Status | null)[]) => void;
  polygonCrossingIndex: number[];
  addPolygonCrossingIndex: (polygonCrossingIndex: number[]) => void;
  removePolygonCrossingIndex: (polygonCrossingIndex: number[]) => void;
  resetPolygonCrossingIndex: () => void;
};

export const useCrossingViewStore = create<CrossingViewStore>((set) => ({
  polygonCrossingIndex: [],
  crossingsStatus: {},
  setCrossingStatus: (id, status) =>
    set((state) => ({ crossingsStatus: { ...state.crossingsStatus, [id]: status } })),
  isLoading: true,
  setIsLoading: (isLoading) => set((state) => ({ isLoading })),
  crossingData: {},
  setCrossingData: (id, crossingData) =>
    set((state) => ({ crossingData: { ...state.crossingData, [id]: crossingData } })),
  addPolygonCrossingIndex: (crossingIndex) =>
    set((state) => ({
      polygonCrossingIndex: Array.from(new Set([...state.polygonCrossingIndex, ...crossingIndex]))
    })),
  removePolygonCrossingIndex: (crossingIndex) =>
    set((state) => ({
      polygonCrossingIndex: state.polygonCrossingIndex.filter((p) => !crossingIndex.includes(p))
    })),
  resetPolygonCrossingIndex: () =>
    set(() => ({
      polygonCrossingIndex: []
    }))
}));
