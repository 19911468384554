import React from 'react';

import { PresetCommentContainer, PresetCommentStyle } from './styles';
interface Props {
  onClickPresetComment: (presetComment: PresetCommentInterface) => void;
  currentPresetComment: PresetCommentInterface[];
  disabled?: boolean;
}

export interface PresetCommentInterface {
  id: string;
  text: string;
  next?: Array<{ id: string; text: string }>;
}
const PresetComments: React.FC<Props> = (props) => {
  const { onClickPresetComment, currentPresetComment, disabled } = props;
  return (
    <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
      <PresetCommentContainer>
        {currentPresetComment.map((comment) => (
          <PresetCommentStyle
            disabled={disabled}
            key={comment.id}
            onClick={
              disabled
                ? () => {}
                : () => onClickPresetComment(comment as unknown as PresetCommentInterface)
            }>
            {comment.text}
          </PresetCommentStyle>
        ))}
      </PresetCommentContainer>
    </div>
  );
};

export default PresetComments;
