import { LoadingSpinner } from '@components/LoaderSpinner';
import { ReactNode } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  z-index: 999999999999999;
  position: absolute;
  inset: 0;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(2px);
  justify-content: center;
  align-items: center;
`;

const Loaders = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: start;
`;

const LoaderRow = styled.div`
  display: flex;
  gap: 6px;
  color: black;
  align-items: center;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
`;

type MapLoaderIndicatorProps = {
  loaders: Record<string, string | ReactNode>;
};

export const MapLoaderIndicator = ({ loaders }: MapLoaderIndicatorProps) => {
  return (
    <Container>
      <Loaders>
        {Object.entries(loaders).map(([key, value]) => {
          return <LoaderRow key={key}>{value}</LoaderRow>;
        })}
      </Loaders>
    </Container>
  );
};
