import { getBearerToken } from '@helpers/auth';

export const fetchDataByUrl = async <T>(url: string, abort?: AbortController): Promise<T> => {
  try {
    const token = await getBearerToken();
    const dataWithURL = await fetch(url, {
      headers: {
        Authorization: token
      },
      signal: abort?.signal
    });

    const URL = await dataWithURL.json();
    const resp = await fetch(URL.url, { signal: abort?.signal });
    return await resp.json();
  } catch (error) {
    return (await {}) as T;
  }
};

export const fetchDataByUrlBlob = async (url: string): Promise<Blob> => {
  const token = await getBearerToken();
  const dataWithURL = await fetch(url, {
    headers: {
      Authorization: token
    }
  });

  const URL = await dataWithURL.json();
  const resp = await fetch(URL.url);
  return await resp.blob();
};
