import 'leaflet/dist/leaflet.css';
import { TablerPolygonIcon } from './PolyginIcon';
import { PolygonKeys, usePolygonStore } from '@store';
import { ControlButton } from '../Controls/ControlButton';

export const CrossingIndexPolygonButton = () => {
  const setCurrentPolygonDrawer = usePolygonStore((s) => s.setCurrentPolygonDrawer);
  const currentPoylgonDrawer = usePolygonStore((s) => s.currentPoylgonDrawer);

  const isCurrent = currentPoylgonDrawer === PolygonKeys.CrossingIndex;

  return (
    <ControlButton
      isSelected={isCurrent}
      onClick={() => setCurrentPolygonDrawer(isCurrent ? undefined : PolygonKeys.CrossingIndex)}>
      <TablerPolygonIcon />
    </ControlButton>
  );
};
