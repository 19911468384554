import escapeHtml from 'escape-html';
import { useNavigate, useSearchParams } from 'react-router-dom';

interface Return {
  setUrlParam: (key: string, val: string | number | boolean) => void;
  getUrlParam: (key: string) => string | null;
  deleteUrlParam: (key: string) => void;
  setManyUrlParam: (params: Array<{ key: string; val: string | number | boolean }>) => void;
}

export const useUrlParams = (): Return => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const setUrlParam = (key: string, val: string | number | boolean): void => {
    searchParams.set(key, encodeURIComponent(val));
    navigate(`${document.location.pathname}?${searchParams.toString()}`);
  };

  const setManyUrlParam = (
    params: Array<{ key: string; val: string | number | boolean }>
  ): void => {
    params.forEach((item) => {
      searchParams.set(item.key, encodeURIComponent(item.val));
    });
    navigate(`${document.location.pathname}?${searchParams.toString()}`);
  };

  const getUrlParam = (key: string): string | null => {
    const value = searchParams.get(key);
    return value && escapeHtml(value);
  };

  const deleteUrlParam = (key: string): void => {
    searchParams.delete(key);
    navigate(`${document.location.pathname}?${searchParams.toString()}`);
  };

  return {
    setUrlParam,
    getUrlParam,
    deleteUrlParam,
    setManyUrlParam
  };
};
