import L, { LatLngExpression } from 'leaflet';
import { CSSProperties, memo } from 'react';
import { Marker } from 'react-leaflet';

type XMarkerProps = {
  size: number;
  position: LatLngExpression;
  color: CSSProperties['color'];
};

export const XMarker = memo(
  ({ position, size, color }: XMarkerProps) => {
    const icon = L.divIcon({
      className: '',
      html: `<svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 24 24"><g fill="none" stroke="${color}" stroke-linecap="round" stroke-width="2"><path d="M7 7L17 17"/><path d="M17 7L7 17"/></g></svg>`,
      iconSize: [size, size]
    });

    return (
      <>
        <Marker position={position} icon={icon}></Marker>
      </>
    );
  },
  (prev, next) => JSON.stringify(next.position) !== JSON.stringify(prev.position)
);
