import { InterpolationData } from '@components/Map';
import { fetchDataByUrl } from '@helpers/fetchData';

export const fetchInterpolationData = async (urls: string[], abort?: AbortController) => {
  if (!urls?.length) return;

  const promises = urls.map(async (u) => u && (await fetchDataByUrl(u, abort))).filter(Boolean);
  const results = await Promise.allSettled(promises);
  const fulfilled = results
    .filter((s): s is PromiseFulfilledResult<InterpolationData> => s.status === 'fulfilled')
    .map((s) => s.value);

  const rejected = results
    .filter((s): s is PromiseRejectedResult => s.status === 'rejected')
    .map((s) => s.reason);

  if (rejected?.length) console.error('Failed to fetch interpolation data', rejected);

  return fulfilled;
};
