import styled from 'styled-components';

export const NavContainer = styled.div`
  min-width: 80px;
  height: 100;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
`;

export const Nav = styled.div<{ $isOpen?: boolean }>`
  background-color: #fff;
  width: ${(props) => (props.$isOpen ? '300px' : '80px')};
  transition: 0.1s ease-in;
  overflow-y: hidden;
  height: 100%;
`;
