export const orthophotoLayerConfig = {
  maxNativeZoom: 22,
  maxZoom: 30
};

export const mapConfig = {
  zoom: 4,
  maxZoom: 30,
  maxNativeZoom: 18
};
