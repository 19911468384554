import type { Scan } from '@graphql/generated';
import type { Option } from '@components/MultiSelectInput';
import { SELECT_ALL } from './MultiSelect';

export const getScanNamesOptions = (scans: Scan[]): Option[] => {
  const uniqueSortedNames = Array.from(new Set(scans.map((s) => s.scan_name))).sort();

  const options = uniqueSortedNames.map((name) => ({
    label: name,
    value: name
  }));

  options.unshift({
    label: SELECT_ALL,
    value: SELECT_ALL
  });

  return options;
};
