import React, { useContext } from 'react';
import { FiltersContainer, Row, StyledCheckbox, Text } from './styles';
import { AnalyzedEntityFilterOptions } from '@helpers/filterData';
import SortContext, { SortBy } from '@contexts/SortContext';
import AnalyzedEntityContext from '@contexts/AnalyzedEntityContext';
import { Status } from '@graphql/generated';

interface Props {
  filter: AnalyzedEntityFilterOptions;
  setFilter: (filter: AnalyzedEntityFilterOptions) => void;
}

const Filter: React.FC<Props> = (props) => {
  const { filter, setFilter } = props;
  const { sortBy, setSortBy } = useContext(SortContext);
  const { statuses, flagged, haveComments } = useContext(AnalyzedEntityContext);
  const { approved, rejected, pending } = statuses;

  const setNewSort = (newSort: SortBy): void => {
    if (sortBy === newSort) {
      setSortBy(undefined);
      return;
    }

    setSortBy(newSort);
  };

  const setNewFilter = <T extends keyof AnalyzedEntityFilterOptions>(
    key: T,
    value: AnalyzedEntityFilterOptions[T]
  ): void => {
    setFilter({
      ...filter,
      [key]: value
    });
  };

  return (
    <FiltersContainer>
      <Row onClick={() => setNewFilter('approved', !filter.approved)}>
        <StyledCheckbox
          checked={filter.approved}
          onChange={() => setNewFilter('approved', !filter.approved)}
        />
        <Text>Approved</Text>
        <Text>{approved}</Text>
      </Row>
      <Row onClick={() => setNewFilter('rejected', !filter.rejected)}>
        <StyledCheckbox
          checked={filter.rejected}
          onChange={() => setNewFilter('rejected', !filter.rejected)}
        />
        <Text>Rejected</Text>
        <Text>{rejected}</Text>
      </Row>
      <Row onClick={() => setNewFilter('pending', !filter.pending)}>
        <StyledCheckbox
          checked={filter.pending}
          onChange={() => setNewFilter('pending', !filter.pending)}
        />
        <Text>Pending Validation</Text>
        <Text>{pending}</Text>
      </Row>
      <Row onClick={() => setNewFilter('flagged', !filter.flagged)}>
        <StyledCheckbox
          checked={filter.flagged}
          onChange={() => setNewFilter('flagged', !filter.flagged)}
        />
        <Text>Flagged</Text>
        <Text>{flagged}</Text>
      </Row>
      <Row onClick={() => setNewFilter('hasComments', !filter.hasComments)}>
        <StyledCheckbox
          checked={filter.hasComments}
          onChange={() => setNewFilter('hasComments', !filter.hasComments)}
        />
        <Text>Have Comments</Text>
        <Text>{haveComments}</Text>
      </Row>
      <Row onClick={() => setNewSort(SortBy.confidence)}>
        <StyledCheckbox
          checked={sortBy === SortBy.confidence}
          onChange={() => setNewSort(SortBy.confidence)}
        />
        <Text>Confidence Sort</Text>
      </Row>
      <Row onClick={() => setNewSort(SortBy.frequency)}>
        <StyledCheckbox
          checked={sortBy === SortBy.frequency}
          onChange={() => setNewSort(SortBy.frequency)}
        />
        <Text>Frequency Sort</Text>
      </Row>
      <Row onClick={() => setNewSort(SortBy.scan)}>
        <StyledCheckbox checked={sortBy === SortBy.scan} onChange={() => setNewSort(SortBy.scan)} />
        <Text>Scan Sort</Text>
      </Row>
    </FiltersContainer>
  );
};

export default Filter;
