import { AnalyzedEntity } from '@contexts/AnalyzedEntityContext';
import { useEffect, useState } from 'react';
import { InterpolationData } from '@components/Map';
import { fetchInterpolationData } from './fetch';
import { useInterpolationDataStore, useMapLoaderStore } from '@store';
import { getNextEnitiesUrls } from './getNextEnitiesUrls';
import { getBearerToken } from '@helpers/auth';
import { ProgressLoader } from '@components/ProgressLoader';
import { getSortedAnalyzedEntities } from '@helpers/filterData';

export const DEFUALT_INTERPOLATION_VALUE = 'y-amplitude-1_None';
export const FIXED_INTERPOLATION_VALUES: string[] = [
  'z-amplitude-1_None',
  'norm(x-amplitude-1,y-amplitude-1)_None',
  DEFUALT_INTERPOLATION_VALUE
];

export const getInterpolations = ({
  defaultInterpolations,
  type
}: {
  defaultInterpolations: string[];
  type: string;
}) => {
  return Array.from(new Set([...defaultInterpolations, type]));
};

export const getItems = ({ analyzedEntity }: { analyzedEntity: AnalyzedEntity }) => {
  return analyzedEntity.scan?.interpolations?.items || [];
};

export const getCurrentTypeUrl = ({ items, type }: { items: any; type: string }) => {
  const currentTypeUrl =
    items.find((item: any) => item?.interpolation_type === type)?.interpolation_url || '';
  return currentTypeUrl;
};

export const getUrls = ({
  items,
  interpolationsToFetch
}: {
  items: any[];
  interpolationsToFetch: string[];
}) => {
  const urls = [];
  for (const item of items) {
    if (interpolationsToFetch.includes(item.interpolation_type)) urls.push(item.interpolation_url);
  }
  return urls;
};

export const useGetInterpolationData = (
  {
    analyzedEntity,
    analyzedEntityList,
    interpolationType
  }: {
    analyzedEntityList: (AnalyzedEntity | undefined)[];
    analyzedEntity: AnalyzedEntity | undefined;
    interpolationType?: string;
  },
  dependencies: any[]
) => {
  const [value, setValue] = useState<{ read: () => InterpolationData | undefined } | undefined>(
    undefined
  );

  useEffect(() => {
    if (!analyzedEntity || !interpolationType) return;
    if (interpolationType === 'NONE') return setValue({ read: () => undefined });
    const items = getItems({ analyzedEntity });

    if (!items.length) return;

    const currentTypeUrl = getCurrentTypeUrl({ items, type: interpolationType });
    const store = useInterpolationDataStore.getState();
    const mapLoadersStore = useMapLoaderStore.getState();

    const abortController = new AbortController();

    const updateStore = ({ urls, data }: { urls: string[]; data: InterpolationData[] }) => {
      urls.forEach((url, i) => {
        if (currentTypeUrl === url) setValue({ read: () => res });
        const res = data[i];
        if (!Object.keys(res).length) return;
        store.setInterpolationData(url, res);
      });
    };

    const fetchCurrentInterpolation = async (url: string) => {
      const loaderId = 'interpolations';

      mapLoadersStore.addLoader({
        id: loaderId,
        value: <ProgressLoader progress={0} />
      });

      try {
        const token = await getBearerToken();
        const dataURL = await fetch(url, {
          headers: {
            Authorization: token
          },
          signal: abortController.signal
        });
        const URL = await dataURL.json();
        const response = await fetch(URL.url, {
          signal: abortController.signal
        });

        const reader = response.body?.getReader();
        const contentLength = +(response.headers.get('Content-Length') || 0);
        let receivedLength = 0;
        const chunks = [];

        if (reader) {
          while (true) {
            const { done, value } = await reader.read();
            if (done) {
              break;
            }

            chunks.push(value);
            receivedLength += value.length;

            const progress = Math.floor((receivedLength / contentLength) * 100);
            mapLoadersStore.addLoader({
              id: loaderId,
              value: <ProgressLoader progress={progress} />
            });
          }

          const chunksAll = new Uint8Array(receivedLength);
          let position = 0;
          for (const chunk of chunks) {
            chunksAll.set(chunk, position);
            position += chunk.length;
          }

          const jsonText = new TextDecoder('utf-8').decode(chunksAll);
          const jsonData = JSON.parse(jsonText);

          if (!Object.keys(jsonData).length) return;
          store.setInterpolationData(url, jsonData);
          setValue({ read: () => jsonData });
        }
      } catch (err: any) {
        console.log(err);
      } finally {
        mapLoadersStore.removeLoader(loaderId);
      }
    };

    const fetchCurrentEntityNextInterpolations = async () => {
      const interpolationsToFetch = FIXED_INTERPOLATION_VALUES.filter(
        (s) => s !== interpolationType
      );
      const urls = getUrls({ items, interpolationsToFetch }).filter(
        (s) => !store.getInterpolation(s)
      );
      if (!urls.length) return;
      const data = await fetchInterpolationData(urls, abortController);
      if (!data) return;
      updateStore({ urls, data });
    };

    const fetchNext = async () => {
      if (!analyzedEntityList || analyzedEntityList.length <= 1 || !analyzedEntity.id) return;
      let startIndex = analyzedEntityList.findIndex((s) => s?.id === analyzedEntity.id) + 1;
      if (startIndex >= analyzedEntityList.length) startIndex = 0;
      if (startIndex <= 0) return;
      for (let i = startIndex; i < startIndex + 4; i++) {
        const currentEntity = analyzedEntityList[i];
        if (!currentEntity) continue;
        const items = currentEntity.scan?.interpolations?.items || [];
        const urls = getUrls({ items, interpolationsToFetch: FIXED_INTERPOLATION_VALUES }).filter(
          (s) => !store.getInterpolation(s)
        );
        if (!urls.length) continue;
        const data = await fetchInterpolationData(urls, abortController);
        if (!data) continue;
        updateStore({ urls, data });
      }
    };

    const fetchInterpolations = async () => {
      await fetchCurrentInterpolation(currentTypeUrl);
      await fetchCurrentEntityNextInterpolations();
      await fetchNext();
    };

    const existingData = store.getInterpolation(currentTypeUrl);
    if (existingData) {
      setValue({ read: () => existingData });
      fetchNext();
      return;
    }

    fetchInterpolations();

    return () => {
      abortController.abort();
    };
  }, dependencies);

  useEffect(() => {}, dependencies);

  return value;
};
