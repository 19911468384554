export const OTHERUTILITIES = {
  ALL: 'all',
  SAME_FREQUENCY: 'same_frequency',
  OFF: 'Off'
};
export const otherUtilitiesValues = {
  all: 'All',
  same_frequency: 'Same Frequency',
  off: 'Off'
};

export type OtherUtilitiesType = keyof typeof otherUtilitiesValues;
export const otherUtilitiesOptions = Object.keys(otherUtilitiesValues).map((key) => ({
  value: key,
  label: otherUtilitiesValues[key as keyof typeof otherUtilitiesValues]
}));
