import React from 'react';
import { createBrowserRouter, RouterProvider, RouteObject } from 'react-router-dom';
import { FILTERS, MAIN, SEARCH } from '@constants/routes';
import Search from '@pages/Search';
import Filters from '@pages/Filters';
import { AnalyzedEntityProvider } from '@contexts/AnalyzedEntityContext';
import { SortProvider } from '@contexts/SortContext';

import Main from '@pages/Main';

const routsArray: RouteObject[] = [
  {
    path: SEARCH,
    element: <Search />
  },
  {
    path: MAIN,
    element: (
      <SortProvider>
        <AnalyzedEntityProvider>
          <Main />
        </AnalyzedEntityProvider>
      </SortProvider>
    )
  },
  {
    path: FILTERS,
    element: <Filters />
  }
];

const router = createBrowserRouter(routsArray);

const Router: React.FC = () => {
  return <RouterProvider router={router} />;
};

export default Router;
