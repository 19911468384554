import styled from 'styled-components';

export const Container = styled.div`
  height: 100px;
  width: 100%;
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
`;

export const Text = styled.div`
  font-size: 23px;
  font-weight: 600;
  margin-left: 15px;
`;
