import { useEffect, useState } from 'react';
import { useSearchSitePlatformTypeLazyQuery } from '@graphql/generated';
import { UNKNOWN } from '@pages/Search/MultiSelect';

export const useGetPlatfromTypesQuery = (siteScanId: string) => {
  const [getData] = useSearchSitePlatformTypeLazyQuery();
  const [platfromTypes, setPlatfromTypes] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const fetchPlatformType = async (siteScanId: string) => {
    try {
      const { data } = await getData({ variables: { siteScanId } });
      let types;
      if (data) {
        const result = data.searchScans?.aggregateItems[0]?.result;
        if (result && 'buckets' in result) {
          types = result?.buckets?.map((item) => item?.key) || [];
          types = types.filter((type): type is string => type !== undefined);
          types.push(UNKNOWN);
        } else {
          types = [UNKNOWN];
        }
      }
      if (types) {
        setPlatfromTypes(types);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching platform type:', error);
      setLoading(false);
    }
  };
  useEffect(() => {
    setLoading(true);
    if (siteScanId) fetchPlatformType(siteScanId);
  }, [siteScanId]);
  return { platfromTypes, loading };
};
