import * as Apollo from '@apollo/client';
import {
  Confidence,
  GetScansByFilterQuery,
  GetScansByFilterQueryVariables,
  ModelAnalyzedEntityFilterInput,
  Scan,
  Status,
  useGetScansByFilterLazyQuery
} from '@graphql/generated';
import { SortBy } from '@contexts/SortContext';
import { AnalyzedEntity } from '@contexts/AnalyzedEntityContext';

type QueryOptions = Apollo.LazyQueryHookOptions<
  GetScansByFilterQuery,
  GetScansByFilterQueryVariables
>;

export interface Filter {
  country: string;
  project: string;
  site: string;
  domain: string;
  scanName: string;
  startDate: string;
  endDate: string;
  analyzedEntityFlagged: boolean;
  analyzedEntitiesIds: string[];
  confidences: Confidence[];
  frequencies: string[];
}
export const getQueryOptions = (filter: Filter): QueryOptions => {
  const analyzedEntityFilterAnd: ModelAnalyzedEntityFilterInput['and'] = [];

  if (filter.analyzedEntityFlagged) {
    analyzedEntityFilterAnd.push({ flagged: { eq: filter.analyzedEntityFlagged } });
  }

  if (filter.analyzedEntitiesIds.length > 0) {
    analyzedEntityFilterAnd.push({
      or: filter.analyzedEntitiesIds.map((item) => {
        return {
          id: { eq: item }
        };
      })
    });
  }

  if (filter.frequencies.length > 0) {
    analyzedEntityFilterAnd.push({
      or: filter.frequencies.map((item) => {
        return {
          frequency: { eq: parseInt(item) }
        };
      })
    });
  }

  if (filter.confidences.length > 0) {
    analyzedEntityFilterAnd.push({
      or: filter.confidences.map((item) => {
        return {
          confidence: { eq: item }
        };
      })
    });
  }

  return {
    variables: {
      scanFilter: {
        and: [
          {
            country: { wildcard: `*${filter.country}*` },
            site_name: { wildcard: `*${filter.site}*` },
            scan_name: { wildcard: `*${filter.scanName}*` },
            scan_date: {
              gte: filter.startDate,
              lte: filter.endDate
            }
          }
        ]
      },
      analyzedEntityFilter:
        analyzedEntityFilterAnd.length > 0
          ? {
              and: analyzedEntityFilterAnd
            }
          : undefined
    }
  };
};

export type NonNullable<T> = Exclude<T, null | undefined>;

export type PartialAnalyzedEntity = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<
        NonNullable<
          NonNullable<
            NonNullable<ReturnType<typeof useGetScansByFilterLazyQuery>[1]['data']>['searchScans']
          >['items']
        >[number]
      >['analyzed_entities']
    >['items']
  >[number]
>;

export const getAnalyzedEntitiesFromScans = (scans: Scan[]): PartialAnalyzedEntity[] => {
  return (
    scans.reduce<PartialAnalyzedEntity[]>((accumulator, currentValue) => {
      if (
        !currentValue?.analyzed_entities ||
        !Array.isArray(currentValue.analyzed_entities.items)
      ) {
        return accumulator;
      }

      return [...accumulator, ...currentValue.analyzed_entities.items] as PartialAnalyzedEntity[];
    }, []) ?? []
  );
};

export interface AnalyzedEntityFilterOptions {
  approved: boolean;
  rejected: boolean;
  pending: boolean;
  flagged: boolean;
  hasComments: boolean;
}

export const getSortedAnalyzedEntities = (
  analyzedEntities: AnalyzedEntity[],
  sortBy: SortBy | undefined = SortBy.scan
): AnalyzedEntity[] => {
  const sortedAnalyzedEntities = [...analyzedEntities];
  if (sortBy === SortBy.confidence) {
    const confidencePriorityArray: Array<Confidence | null | undefined> = [
      Confidence.Lowest,
      Confidence.Low,
      Confidence.Medium,
      Confidence.High,
      Confidence.Highest
    ];
    sortedAnalyzedEntities.sort(
      (a, b) =>
        confidencePriorityArray.indexOf(b.confidence) -
        confidencePriorityArray.indexOf(a.confidence)
    );
  }

  if (sortBy === SortBy.frequency) {
    sortedAnalyzedEntities.sort(
      (a, b) => (a.frequency ? a.frequency : 0) - (b.frequency ? b.frequency : 0)
    );
  }

  if (sortBy === SortBy.scan) {
    sortedAnalyzedEntities.sort((a, b) => {
      if ((a.scan?.site_name ?? '') < (b.scan?.site_name ?? '')) {
        return -1;
      }
      if ((a.scan?.site_name ?? '') > (b.scan?.site_name ?? '')) {
        return 1;
      }
      if ((a.scan?.scan_name ?? '') < (b.scan?.scan_name ?? '')) {
        return -1;
      }
      if ((a.scan?.scan_name ?? '') > (b.scan?.scan_name ?? '')) {
        return 1;
      }
      return 0;
    });
  }
  return sortedAnalyzedEntities;
};

export const getFilteredAnalyzedEntities = (
  analyzedEntities: PartialAnalyzedEntity[],
  filter: AnalyzedEntityFilterOptions
): PartialAnalyzedEntity[] => {
  let filteredAnalyzedEntities = analyzedEntities;

  if (filter.flagged) {
    filteredAnalyzedEntities = filteredAnalyzedEntities.filter((item) => item.flagged);
  }

  if (filter.approved) {
    filteredAnalyzedEntities = filteredAnalyzedEntities.filter(
      (item) => item.status === Status.Approved
    );
  }

  if (filter.rejected) {
    filteredAnalyzedEntities = filteredAnalyzedEntities.filter(
      (item) => item.status === Status.Rejected
    );
  }

  if (filter.pending) {
    filteredAnalyzedEntities = filteredAnalyzedEntities.filter(
      (item) => item.status === Status.Pending
    );
  }

  if (filter.hasComments) {
    filteredAnalyzedEntities = filteredAnalyzedEntities.filter(
      (item) => item.comments?.items.length
    );
  }

  return filteredAnalyzedEntities;
};

export const getFilterFormURL = (): Filter => {
  const searchParams = new URL(document.location.href).searchParams;

  const analyzedEntitiesIdsString = searchParams.get('analyzedEntitiesIds');
  const analyzedEntitiesIds = analyzedEntitiesIdsString ? analyzedEntitiesIdsString.split(',') : [];
  const confidencesString = searchParams.get('confidences');
  const confidences = (confidencesString ? confidencesString.split(',') : []) as Confidence[];
  const frequenciesString = searchParams.get('frequencies');
  const frequencies = frequenciesString ? frequenciesString.split(',') : [];

  return {
    country: searchParams.get('country') ?? '',
    project: searchParams.get('project') ?? '',
    site: searchParams.get('site') ?? '',
    domain: searchParams.get('domain') ?? '',
    scanName: searchParams.get('scanName') ?? '',
    startDate: searchParams.get('startDate') ?? '',
    endDate: searchParams.get('endDate') ?? '',
    analyzedEntityFlagged: searchParams.get('analyzedEntityFlagged') === 'true',
    analyzedEntitiesIds,
    confidences,
    frequencies
  };
};
