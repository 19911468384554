import React, { useContext } from 'react';
import {
  Container,
  UtilityID,
  Icon,
  Text,
  Row,
  LeftContainer,
  RightContainer,
  ArrowForwardIcon
} from './styles';
import CopyIcon from '@assets/copy.png';
import FlagIcon from '@assets/flag.svg';
import ArrowIcon from '@assets/arrow.svg';
import Status from './Status';
import Button from '@components/Button';
import UserContext from '@contexts/UserContext';
import AnalyzedEntityContext, { AnalyzedEntity } from '@contexts/AnalyzedEntityContext';
import Statuses from './StatusAmount';

interface Props {
  onClickPrevUtility: () => void;
  onClickNextUtility: () => void;
}

const getIsPassiveFrequency = (entity?: AnalyzedEntity) => {
  return entity?.scan_frequency?.transmitters?.items[0]?.method === 'PASSIVE';
};

const Header: React.FC<Props> = (props) => {
  const { onClickPrevUtility, onClickNextUtility } = props;
  const { user } = useContext(UserContext);
  const { analyzedEntity } = useContext(AnalyzedEntityContext);
  const copyId = (): void => {
    navigator.clipboard
      .writeText(analyzedEntity?.id ?? '')
      .catch((e) => console.error('error during copying Id', e));
  };

  const frequency = analyzedEntity?.frequency;
  const snr = analyzedEntity?.snr;
  const isPassive = getIsPassiveFrequency(analyzedEntity);

  return (
    <Container>
      <LeftContainer>
        <Row>
          <UtilityID>Utility ID: {analyzedEntity?.prefix}</UtilityID>
          <Icon src={CopyIcon} onClick={copyId} />
        </Row>
        <Status status={analyzedEntity?.status ?? undefined} />
        <Statuses />
        <Row>
          <Text>By {user?.account?.username}</Text>
          <Icon src={FlagIcon} />
        </Row>
        <Row>
          <div style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
            {frequency !== undefined && (
              <>
                <span style={{ fontWeight: 600 }}>{frequency}</span>
                <span>(Hz)</span>{' '}
                {isPassive && <span style={{ fontSize: 14, color: 'gray' }}>Passive</span>}
              </>
            )}
          </div>
          {snr && (
            <div style={{ marginLeft: 14, display: 'inline-block', whiteSpace: 'nowrap' }}>
              <span style={{ fontWeight: 600 }}>SNR:</span>{' '}
              <span style={{ fontSize: 16 }}>{snr}</span>
            </div>
          )}
        </Row>
      </LeftContainer>
      <RightContainer>
        <Button onClick={onClickPrevUtility}>
          <Row>
            <Icon src={ArrowIcon} />
            Previous
          </Row>
        </Button>
        <Button onClick={onClickNextUtility}>
          <Row>
            Next <ArrowForwardIcon src={ArrowIcon} />
          </Row>
        </Button>
      </RightContainer>
    </Container>
  );
};

export default Header;
