import styled from 'styled-components';
import DropdownInput from '@components/DropdownInput';
import DateInput from '@components/DateInput';

export const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
`;

export const DropdownInputWithMargin = styled(DropdownInput)`
  margin: 20px;
  width: 800px;
  font-size: 24px;
  background-color: green;
`;

export const ListBox = styled.ul<{ height?: string }>`
  list-style-type: none;
  padding: 0;
  margin: 0;
  background-color: white;
  border: 1px solid #ccc;
  overflow-y: auto;
  height: ${({ height }) => height || '250px'}; /* Default height is 250px */
`;

export const ListItem = styled.li`
  padding: 10px;
  border-bottom: 1px solid #ccc;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: #f0f0f0;
  }
`;

export const DateInputWithMargin = styled(DateInput)`
  margin: 20px;
`;

export const InputContainer = styled.div`
  margin: 20px;
`;

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
  width: 800px;
  margin: auto;
  height: 100%;
  box-sizing: border-box;
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  margin: 20px;
  gap: 20px;
  width: 400px;
`;
