import styled from 'styled-components';

export const Container = styled.div`
  height: 64px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  overflow-x: auto;
`;

export const LeftContainer = styled.div`
  width: 55%;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const RightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 6px;
  flex: 1;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const UtilityID = styled.div`
  color: rgba(57, 68, 82, 1);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  max-width: 400px;
  word-break: break-all;
  white-space: nowrap;
`;

export const Text = styled.div`
  color: #272833;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  white-space: nowrap;
`;

export const Icon = styled.img`
  cursor: pointer;

  &:hover {
    filter: brightness(85%);
  }
`;

export const ArrowForwardIcon = styled(Icon)`
  transform: rotate(180deg);
`;
