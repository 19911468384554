import React from 'react';
import L, { LatLngTuple } from 'leaflet';
import { Marker, Polyline } from 'react-leaflet';

interface Props {
  depth: number;
  center: LatLngTuple;
  points: LatLngTuple[];
}

const calculateOffsetCoordinates = (lat: number, lng: number, offsetX: number, offsetY: number) => {
  const earthRadius = 6378137;
  const latOffset = (offsetY / earthRadius) * (180 / Math.PI);
  const lngOffset = ((offsetX / earthRadius) * (180 / Math.PI)) / Math.cos(lat * (Math.PI / 180));

  return {
    lat: lat + latOffset,
    lng: lng + lngOffset
  };
};

const DepthItem: React.FC<Props> = (props) => {
  const { center, points, depth } = props;

  return (
    <>
      {points.map((point, index) => {
        const distanceX = -0.1;
        const distanceY = 0.1;
        const textPosition = calculateOffsetCoordinates(center[0], center[1], distanceX, distanceY);

        const textIcon = L.divIcon({
          className: 'custom-div-icon',
          html: `<div style="color: black; font-size: 14px; font-weight:600;">${depth.toFixed(
            2
          )}</div>`,
          iconSize: [50, 20]
        });

        return (
          <React.Fragment key={`${point}_${index}_${center}`}>
            <Polyline positions={[point, center]} color={'#ffffff'} />
            <Marker position={textPosition} icon={textIcon} />
          </React.Fragment>
        );
      })}
    </>
  );
};

export default DepthItem;
