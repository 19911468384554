import styled from 'styled-components';
import { Status } from '@graphql/generated';

const BackgroundColors = {
  [Status.Approved]: '#008744',
  [Status.Rejected]: '#ff5757',
  [Status.Pending]: '#afafaf',
  [Status.Test]: '#fa7b51',
  [Status.InvalidatedByExofuser]: '#000000', // temporary color,
  [Status.NoValidationRequired]: '#000000' // temporary color
};

const getBackgroundColor = (type: Status): string => {
  return BackgroundColors[type];
};

export const Container = styled.div<{ type: Status }>`
  padding: 2px 8px;
  background: ${(props) => getBackgroundColor(props.type)};
  font-weight: 600;
  line-height: 24px;
  color: whitesmoke;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
`;
