import { useState } from 'react';
import {
  type Scan,
  useSearchAllScansInfoLazyQuery,
  useSearchSitePlatformTypeLazyQuery
} from '@graphql/generated';
import { reportAllPagesResults } from '@helpers/reportAllPagesResults';
import { NON_UNIFY, UNIFIED, UNIFY } from '@pages/Search/constans';
import { UNKNOWN } from '@pages/Search/MultiSelect';

interface Result {
  scans: Scan[];
  getScans: () => Promise<void>;
  loading: boolean;
}

export const useAllScansQuery = (
  siteScanId: string,
  selectedConfidence: string[],
  selectedStatus: string[],
  selectedUnify: string[],
  selectedPlatformType: string[]
): Result => {
  const [getData] = useSearchAllScansInfoLazyQuery();
  const [scans, setSScans] = useState<Scan[]>([]);
  const [loading, setLoading] = useState(false);

  const getDataByFilters = async () => {
    // Clear existing scans before new search
    setSScans([]);

    if (
      !siteScanId ||
      selectedConfidence.length === 0 ||
      selectedStatus.length === 0 ||
      selectedUnify.length === 0 ||
      selectedPlatformType.length === 0
    )
      return;

    setLoading(true);

    let allScans: Scan[] = []; // Temporary array to collect all scans

    await reportAllPagesResults(
      getData,
      (res) => {
        const filteredItems =
          res?.data?.searchScans?.items?.filter((item) => item?.analyzed_entities?.items?.length) ||
          [];

        const filterScans = filteredItems
          .map((item) => {
            return {
              ...item,
              analyzed_entities: {
                ...item?.analyzed_entities,
                items: item?.analyzed_entities?.items?.filter((entity) => {
                  return (
                    ((selectedUnify.includes(UNIFY) && item?.scan_name?.includes(UNIFIED)) ||
                      (selectedUnify.includes(NON_UNIFY) && !item?.scan_name?.includes(UNIFIED))) &&
                    selectedConfidence.includes((entity?.confidence || '').toUpperCase()) &&
                    selectedStatus.includes((entity?.status || '').toUpperCase()) &&
                    ((selectedPlatformType.includes(UNKNOWN) && !item?.platform_type) ||
                      selectedPlatformType.includes(item?.platform_type || ''))
                  );
                })
              }
            };
          })
          .filter((item) => item?.analyzed_entities?.items?.length);

        // Collect filtered scans instead of immediately updating state
        allScans = [...allScans, ...(filterScans as Scan[])];
      },
      (res) => res.data?.searchScans?.nextToken,
      { siteScanId }
    ).finally(() => {
      // Set all collected scans at once after all pages are processed
      setSScans(allScans);
      setLoading(false);
    });
  };

  return {
    scans,
    getScans: getDataByFilters,
    loading
  };
};
