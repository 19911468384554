import styled from 'styled-components';

export const StatusesDiv = styled.div`
  font-size: 25px;
  font-weight: bold;
      display: flex
  align-items: center;
`;

export const NullDiv = styled.span`
  font-size: 16px;
  font-weight: normal;
  color: red;
`;
