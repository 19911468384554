import { AnalyzedEntity } from '@contexts/AnalyzedEntityContext';
import { fetchDataByUrl } from '@helpers/fetchData';
import { useEffect, useState } from 'react';
import { FeatureCollection } from 'geojson';

export const useScanPath = (analyzedEntity: AnalyzedEntity | undefined, depndencies: any[]) => {
  const [value, setValue] = useState<FeatureCollection>();
  useEffect(() => {
    if (!analyzedEntity?.id || !analyzedEntity?.scan?.scan_path_url) return;

    fetchDataByUrl<FeatureCollection>(analyzedEntity?.scan?.scan_path_url).then((data) => {
      setValue(data);
    });
  }, depndencies);
  return value;
};
