import React, { ChangeEvent } from 'react';
import { Slider, SwitchInput, SwitchWrapper } from './styles';

interface Props {
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  disabled?: boolean;
}

const Switch: React.FC<Props> = (props) => {
  const { checked, onChange, disabled } = props;

  const onClick = (e: ChangeEvent<HTMLInputElement>): void => {
    const { checked } = e.target;
    onChange?.(checked);
  };

  return (
    <SwitchWrapper>
      <SwitchInput type="checkbox" checked={checked} onChange={onClick} disabled={disabled} />
      <Slider />
    </SwitchWrapper>
  );
};

export default Switch;
