import { useContext } from 'react';
import { useEffectAsync } from '@hooks/useEffectAsync';
import AnalyzedEntityContext, { DepthListType } from '@contexts/AnalyzedEntityContext';
import { fetchDataByUrl } from '@helpers/fetchData';

const defaultSelectedDepth = 'z-amplitude-1';

export const useCurrentDepth = (): void => {
  const { analyzedEntity, setDepthList, setSelectedDepth } = useContext(AnalyzedEntityContext);

  useEffectAsync(async () => {
    if (!analyzedEntity?.id) {
      return;
    }

    const depthURL = analyzedEntity?.z_peaks_depth_algo_view;

    if (!depthURL) {
      setDepthList({});
      setSelectedDepth(undefined);
      return;
    }

    const data = await fetchDataByUrl<DepthListType>(depthURL);

    if (!data) {
      setDepthList({});
      setSelectedDepth(undefined);
      return;
    }

    setDepthList(data);

    if (data[defaultSelectedDepth]) {
      setSelectedDepth({
        depth: data[defaultSelectedDepth],
        key: defaultSelectedDepth
      });
    }
  }, [analyzedEntity?.id]);
};
