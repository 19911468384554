import React, { Fragment, useContext } from 'react';
import DepthItem from '@components/Map/DepthList/DepthItem';
import AnalyzedEntityContext from '@contexts/AnalyzedEntityContext';
import { Status } from '@graphql/generated';
import { useCrossingViewStore } from '@store';

const DepthList: React.FC = () => {
  const { selectedDepth, analyzedEntity } = useContext(AnalyzedEntityContext);
  const { crossingsStatus } = useCrossingViewStore();
  const crossingId = analyzedEntity?.crossings?.items[0]?.id;
  const crossingValues = crossingsStatus && crossingId ? crossingsStatus[crossingId] : undefined;
  return (
    <>
      {selectedDepth?.depth.map((d) => {
        const status = crossingValues ? crossingValues[d.crossing_index] : undefined;
        return !status || status !== Status.Rejected ? (
          <DepthItem
            depth={d.depth}
            center={d.location.center}
            points={d.location.points ?? []}
            key={`${d.crossing_index}--${d.depth}`}
          />
        ) : (
          <Fragment key={d.crossing_index}></Fragment>
        );
      })}
    </>
  );
};

export default DepthList;
