import React, { useContext } from 'react';
import AnalyzedEntityContext from '@contexts/AnalyzedEntityContext';
import L, { LatLngExpression } from 'leaflet';
import { Marker } from 'react-leaflet';
import { useZoomLevel } from '@hooks/useZoomLevel';

const sizeCalc = (zoom: number) => {
  return 512 / Math.pow(2, 25 - zoom);
};

const TransmitterList: React.FC = () => {
  const { analyzedEntity } = useContext(AnalyzedEntityContext);
  const transmitters = analyzedEntity?.scan_frequency?.transmitters?.items.filter(
    (item) => !!item?.geometry
  );
  const { zoomLevel } = useZoomLevel();

  return (
    <>
      {transmitters?.map((item, _) => {
        const str = item?.geometry ?? '';
        const json = JSON.parse(str);
        const center = json?.geometry?.coordinates?.reverse() as unknown as LatLngExpression;

        const size = sizeCalc(zoomLevel);
        const innerSize = size / 3;

        const icon = L.divIcon({
          className: '',
          html: `<div style="width: ${size}px; height: ${size}px; background-color: red; opacity: 0.2; border-radius: 50%; display: flex; align-items: center; justify-content: center;">
              <div style="width: ${innerSize}px; height: ${innerSize}px; background-color: red; opacity: 1; border-radius: 50%; border: 2px solid black;"></div>
          </div>`,
          iconSize: [size, size]
        });

        if (center) {
          return <Marker position={center} icon={icon} key={item?.geometry} />;
        }
      })}
    </>
  );
};

export default TransmitterList;
