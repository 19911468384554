import React, { useState } from 'react';
import { Container, HomeContainer, Icon, InnerContainer, Pin, PinnedIcon } from './styles';
import HomeIcon from '@assets/home.svg';
import PinIcon from '@assets/pin.svg';
import UnpinIcon from '@assets/unpin.svg';
import Filter from './Filter';
import { useNavigate } from 'react-router-dom';
import { FILTERS, SEARCH } from '@constants/routes';
import {
  getFilteredAnalyzedEntities,
  AnalyzedEntityFilterOptions,
  PartialAnalyzedEntity
} from '@helpers/filterData';

import IdList from '@pages/Main/NavigationBarContent/IdList';

const InitFilter: AnalyzedEntityFilterOptions = {
  approved: false,
  rejected: false,
  pending: false,
  flagged: false,
  hasComments: false
};

interface Props {
  isOpen?: boolean;
  analyzedEntities: PartialAnalyzedEntity[];
  emptyAnalyzedEntitiesIdList: string[];
  setId: (id: string) => void;
  onMouseOver?: () => void;
  pinned: boolean;
  setPinned: (isPinned: boolean) => void;
}

const NavigationBarContent: React.FC<Props> = (props) => {
  const {
    isOpen,
    analyzedEntities,
    setId,
    onMouseOver,
    setPinned,
    pinned,
    emptyAnalyzedEntitiesIdList
  } = props;

  const navigate = useNavigate();
  const [filter, setFilter] = useState<AnalyzedEntityFilterOptions>(InitFilter);

  const onClickHome = (): void => navigate(SEARCH);
  const filteredAnalyzedEntities = getFilteredAnalyzedEntities(analyzedEntities, filter);

  const ids = filteredAnalyzedEntities.map((item) => item.id);

  return (
    <Container>
      <HomeContainer onMouseOver={onMouseOver}>
        <Icon src={HomeIcon} onClick={onClickHome} />
        <Pin
          onClick={() => {
            setPinned(!pinned);
          }}>
          {pinned ? <PinnedIcon src={PinIcon} /> : <PinnedIcon src={UnpinIcon} />}
        </Pin>
      </HomeContainer>
      {isOpen && (
        <>
          <Filter filter={filter} setFilter={setFilter} />
          <InnerContainer>
            <IdList ids={ids} onClick={setId} />
            {emptyAnalyzedEntitiesIdList.length > 0 && (
              <IdList ids={emptyAnalyzedEntitiesIdList} onClick={() => {}} disabled={true} />
            )}
          </InnerContainer>
        </>
      )}
    </Container>
  );
};

export default NavigationBarContent;
