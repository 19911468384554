import { useEffect, useState } from 'react';
import Select, { Props, MultiValue } from 'react-select';

export type Option = {
  value: string;
  label: string;
};

export const SELECT_ALL = 'Select All';
export const UNKNOWN = 'UNKNOWN';

export const MultiSelect = ({
  onChange,
  options,
  defaultValue,
  label,
  ...rest
}: Omit<Props<Option>, 'onChange' | 'options'> & {
  onChange: (newVal: string[]) => void;
  options: Option[];
  label?: string;
}) => {
  const [value, setValue] = useState<MultiValue<Option>>(
    defaultValue ? (defaultValue as MultiValue<Option>) : []
  );

  const handleChange = (newValue: MultiValue<Option>) => {
    if (!newValue) return;
    if (newValue.at(-1)?.value === SELECT_ALL) {
      setValue(options.filter((option) => option.value !== SELECT_ALL));
    } else {
      setValue(newValue.filter((s) => s.value !== SELECT_ALL));
    }
  };
  useEffect(() => {
    const selectedValues = value.find((s) => s.value === SELECT_ALL)
      ? options.map((s) => s.value).filter((s) => s !== SELECT_ALL)
      : value.map((s) => s.value).filter((s) => s !== SELECT_ALL);
    onChange(selectedValues);
  }, [value, options, onChange]);

  return (
    <div>
      {label && <label>{label}</label>}
      <Select
        {...rest}
        defaultValue={defaultValue}
        value={value}
        options={options}
        getOptionLabel={(p: Option) => p.label}
        getOptionValue={(p: Option) => p.value}
        onChange={(newVal) => handleChange(newVal as MultiValue<Option>)}
        isMulti
      />
    </div>
  );
};
