import React from 'react';
import { Icon, Row, StatusButtonsContainer } from './styles';
import Button from '@components/Button';
import FlagIcon from '@assets/flag.svg';
import CloseIcon from '@assets/close.svg';
import ApproveIcon from '@assets/approve.svg';
import { ButtonTypes } from '@components/Button/styles';

interface Props {
  onApprove: () => void;
  onFlag: () => void;
  onReject: () => void;
}

const StatusButtons: React.FC<Props> = (props) => {
  const { onApprove, onReject, onFlag } = props;
  return (
    <StatusButtonsContainer>
      <Button onClick={onApprove} type={ButtonTypes.approve}>
        <Row>
          <Icon src={ApproveIcon} />
          Approve
        </Row>
      </Button>
      <Button onClick={onFlag}>
        <Row>
          <Icon src={FlagIcon} />
          Flag
        </Row>
      </Button>
      <Button onClick={onReject} type={ButtonTypes.reject}>
        <Row>
          <Icon src={CloseIcon} />
          Reject
        </Row>
      </Button>
    </StatusButtonsContainer>
  );
};

export default StatusButtons;
