import React, { useState, useContext } from 'react';
import './App.css';
import { logIn } from '@helpers/auth';
import { useEffectAsync } from '@hooks/useEffectAsync';
import Router from '@routing/Router';
import { ApolloProvider } from '@apollo/client';
import client from '@graphql/client';
import UserContext from '@contexts/UserContext';
import LogRocket from 'logrocket';

const App: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { setUser } = useContext(UserContext);

  useEffectAsync(async () => {
    const user = await logIn();
    setUser(user);
    setIsLoggedIn(true);
    if (process.env.NODE_ENV === 'development') return;
    LogRocket.init('0bo4qw/pipesqa');
    if (user?.account?.homeAccountId !== undefined && user?.account?.name !== undefined) {
      LogRocket.identify(user.account.homeAccountId, {
        name: user.account.name,
        email: (user.account as any).username
      });
    }
  }, []);

  if (!isLoggedIn) {
    return null;
  }

  return (
    <ApolloProvider client={client}>
      <Router />
    </ApolloProvider>
  );
};

export default App;
