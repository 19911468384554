import { PolygonKeys, useCrossingViewStore, usePolygonStore } from '@store';
import { v4 as uuid } from 'uuid';
import { PolygonList } from './PolygonList';
import { PolygonDrawer } from './PolygonDrawer';
import { FC, useContext, useEffect } from 'react';
import AnalyzedEntityContext from '@contexts/AnalyzedEntityContext';
import { Polygon } from './types';

type PolygonLayerProps = {
  onCreate: (polygon: Polygon, key: PolygonKeys) => void;
  onRemove: (polygon: Polygon, key: PolygonKeys) => void;
};

export const PolygonLayer: FC<PolygonLayerProps> = ({ onCreate, onRemove }) => {
  const analyzedEntity = useContext(AnalyzedEntityContext).analyzedEntity;

  const currentPolygonDrawer = usePolygonStore((s) => s.currentPoylgonDrawer);
  const polygons = usePolygonStore((s) => s.polygons);

  const addPolygon = usePolygonStore((s) => s.addPolygon);
  const resetPolygons = usePolygonStore((s) => s.resetPolygons);
  const removePolygon = usePolygonStore((s) => s.removePolygon);

  const resetPolygonCrossingIndex = useCrossingViewStore((s) => s.resetPolygonCrossingIndex);

  useEffect(() => {
    resetPolygons();
    resetPolygonCrossingIndex();
  }, [analyzedEntity]);

  const handleCreatePolygon = (polygon: Polygon) => {
    const id = uuid();
    if (!currentPolygonDrawer) return;
    addPolygon({ polygon: { [id]: polygon }, key: currentPolygonDrawer });
    onCreate(polygon, currentPolygonDrawer);
  };

  const handleRemovePolygon = (id: string, key: PolygonKeys) => {
    removePolygon({ id, key });
    const currentPolygons = polygons[key];
    if (!currentPolygons) return;
    onRemove(currentPolygons[id], key);
  };

  return (
    <>
      <PolygonList polygons={polygons} onRemove={handleRemovePolygon} />
      {currentPolygonDrawer && <PolygonDrawer onCreate={handleCreatePolygon} />}
    </>
  );
};
