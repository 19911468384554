import React, { ChangeEvent } from 'react';
import { Container, CheckboxInput } from './styles';

interface Props {
  checked?: boolean;
  onChange?: (value?: boolean) => void;
  className?: string;
  id?: string;
  size?: number;
  disabled?: boolean;
}

const Checkbox: React.FC<Props> = (props) => {
  const { checked, onChange, className, id, size, disabled } = props;

  const onClick = (e: ChangeEvent<HTMLInputElement>): void => {
    const { checked } = e.target;
    onChange?.(checked);
  };

  return (
    <CheckboxInput
      disabled={disabled}
      style={{
        height: size,
        width: size
      }}
      onChange={onClick}
      checked={checked}
      type="checkbox"
      className={className}
      id={id}
    />
  );
};

export default Checkbox;
