import styled from 'styled-components';

export const Icon = styled.img`
  width: 40px;
  height: 40px;
  cursor: pointer;
`;
export const PinnedIcon = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const InnerContainer = styled.div`
  height: 100%;
  overflow: auto;
  flex: 1;
`;
export const HomeContainer = styled.div`
  display: flex;
  padding-left: 20px;
  align-items: center;
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
  height: 64px;
  position: relative;
`;
export const Pin = styled.span`
  position: absolute;
  left: 250px;
`;
