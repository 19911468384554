import { FC, useState } from 'react';
import { Polygon, useMapEvents } from 'react-leaflet';

type PolygonDrawerProps = {
  onCreate: (newPolygon: [number, number][]) => void;
};

export const PolygonDrawer: FC<PolygonDrawerProps> = ({ onCreate }) => {
  const [currentMousePosition, setCurrentMousePosition] = useState<[number, number]>([0, 0]);
  const [points, setPoints] = useState<[number, number][]>([]);

  const reset = () => {
    setPoints([]);
    setCurrentMousePosition([0, 0]);
  };

  useMapEvents({
    mousemove: (event) => {
      const { lat, lng } = event.latlng;
      setCurrentMousePosition([lat, lng]);
    },
    click: (event) => {
      const { lat, lng } = event.latlng;
      setPoints((points) => [...points, [lat, lng]]);
    },
    keydown: (event) => {
      const code = event.originalEvent.code;
      if (code.toLowerCase() !== 'escape' || points.length < 2) return;

      onCreate([...points, [currentMousePosition[0], currentMousePosition[1]]]);

      reset();
    }
  });

  return (
    <Polygon
      positions={[...points, [currentMousePosition[0], currentMousePosition[1]]]}
      pathOptions={{ color: 'orange', fillColor: 'orange' }}
    />
  );
};
