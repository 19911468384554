import Control from 'react-leaflet-custom-control';
import { CrossingIndexPolygonButton } from '../Polygon/CrossingIndexPolyginButton';

export const MapControls = () => {
  return (
    <Control position="topleft">
      <CrossingIndexPolygonButton />
    </Control>
  );
};
