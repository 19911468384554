import L, { DoneCallback, TileLayerOptions } from 'leaflet';
import { createLayerComponent } from '@react-leaflet/core';
import axios from 'axios';
import { getBearerToken } from '@helpers/auth';

function transformUrl(originalUrl: string): string {
  const urlPattern = /\/tiles\/(\d+)\/(\d+)\/(\d+)\/png/;
  const match = originalUrl.match(urlPattern);

  if (match) {
    const [, z, x, y] = match;

    const tileUrl = process.env.REACT_APP_BACKEND_TILE_HOST;
    if (!tileUrl) {
      return originalUrl;
    }

    const transformedUrl = `${tileUrl}/tiles_server_api_gateway_pipesqa-prod_resource?z=${z}&x=${x}&y=${y}`;

    return transformedUrl;
  } else {
    return originalUrl;
  }
}

async function fetchTileImage(sourceUrl: string): Promise<string | undefined | null> {
  const cache = await caches.open('tile-cache');

  const url = transformUrl(sourceUrl);

  const cacheResponse = await cache.match(url);
  if (cacheResponse) {
    const blob = await cacheResponse.blob();
    return URL.createObjectURL(blob);
  }

  try {
    const response = await axios.get(url, { headers: { Authorization: await getBearerToken() } });
    const tileResponse = await axios.get(response.data.url, { responseType: 'blob' });
    const blobUrl = URL.createObjectURL(tileResponse.data);

    const blob = new Blob([tileResponse.data], { type: 'image/png' });
    const existingResponse = await cache.match(url);
    if (!existingResponse) {
      await cache.put(url, new Response(blob));
    }

    return blobUrl;
  } catch (error) {}
}

class Authorized extends L.TileLayer {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/no-explicit-any
  public static createAuthorizedLayer(props: any, context: any) {
    const instance = new Authorized(props.url, {
      ...props
    } satisfies TileLayerOptions);
    return { instance, context };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static updateAuthorizedLayer(instance: any, props: any, prevProps: any): void {
    if (prevProps.opacity !== props.opacity) {
      if (instance.setOpacity) {
        instance.setOpacity(props.opacity);
      }
    }
    if (prevProps.zIndex !== props.zIndex) {
      if (instance.setZIndex) {
        instance.setZIndex(props.zIndex);
      }
    }
  }

  createTile(coords: L.Coords, done: DoneCallback): HTMLElement {
    const url = this.getTileUrl(coords);
    const img = document.createElement('img');

    fetchTileImage(url)
      .then((blobUrl) => {
        if (blobUrl === undefined || blobUrl === null) {
          return;
        }
        img.src = blobUrl;
        done(undefined, img);
        return img;
      })
      .catch((e) => {});

    return img;
  }
}

export default createLayerComponent(
  Authorized.createAuthorizedLayer,
  Authorized.updateAuthorizedLayer
);
