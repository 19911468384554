import React from 'react';
import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Loader = styled.div<{ size: number }>`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: ${(p) => `${p.size}px`};
  height: ${(p) => `${p.size}px`};
  animation: ${spin} 1s linear infinite;
`;

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex: 1;
`;

type Props = {
  size?: number;
};

export const LoadingSpinner = ({ size = 40 }: Props) => (
  <LoaderWrapper>
    <Loader size={size} />
  </LoaderWrapper>
);
