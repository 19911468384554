import styled from 'styled-components';

export const PageContainer = styled.div`
  display: flex;
  height: 100%;
`;

export const Container = styled.div`
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const InnerContainer = styled.div`
  display: flex;
  padding: 24px;
  flex: 1;
  box-sizing: border-box;
  gap: 16px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 12px;
  overflow: hidden;
`;

export const MapContainer = styled.div`
  flex: 3;
`;
