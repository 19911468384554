import { ApolloClient, InMemoryCache, createHttpLink, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getBearerToken } from '@helpers/auth';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL ?? ''
});

const authMiddleware = setContext(async (operation) => {
  const token = await getBearerToken();

  return {
    headers: {
      authorization: token || null
    }
  };
});

const link = ApolloLink.from([authMiddleware]);

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: link.concat(httpLink)
});

export default client;
