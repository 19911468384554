import React from 'react';
import { GeoJsonObject } from 'geojson';
import GeoJsonWithUpdates from '@components/Map/GeoJsonWithUpdates';
import { GeoJSONOptions } from 'leaflet';
import { useUrlParams } from '@hooks/useUrlParams';
import { Confidence } from '@graphql/generated';
import { confidenceColors } from '@constants/colors';

export interface OtherUtilityData {
  id: string;
  analysisConfidence?: Confidence | null | undefined;
  geometry: GeoJsonObject;
  frequency: number | null | undefined;
}

interface Props {
  otherUtilitiesData?: OtherUtilityData[];
}

const styles: GeoJSONOptions['style'] = {
  color: 'black'
};

const OtherUtilities: React.FC<Props> = (props) => {
  const { otherUtilitiesData } = props;
  const { setUrlParam } = useUrlParams();

  if (otherUtilitiesData === undefined) {
    return null;
  }
  const redirectToEntity = (id: string): void => {
    setUrlParam('currentId', id);
  };
  return (
    <>
      {otherUtilitiesData?.map((item) => {
        const color =
          item?.analysisConfidence && item.analysisConfidence in confidenceColors
            ? confidenceColors[item.analysisConfidence as keyof typeof confidenceColors]
            : styles.color;
        return (
          <GeoJsonWithUpdates
            data={item.geometry}
            key={item.id}
            style={{ color }}
            eventHandlers={{ click: () => redirectToEntity(item.id) }}
          />
        );
      })}
    </>
  );
};

export default OtherUtilities;
