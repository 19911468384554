import styled from 'styled-components';
export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

export const ModalContainer = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  text-align: center;
  max-width: 90%;
`;

export const ModalHeader = styled.div`
  margin-bottom: 20px;
`;

export const ModalBody = styled.div`
  margin-bottom: 20px;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: center;
`;

export const ModalButton = styled.button<{ variant?: 'primary' | 'default' }>`
  margin-left: 10px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${({ variant }) => (variant === 'primary' ? '#007bff' : '#f8f9fa')};
  color: ${({ variant }) => (variant === 'primary' ? '#fff' : '#000')};

  &:hover {
    background-color: ${({ variant }) => (variant === 'primary' ? '#0056b3' : '#ddd')};
  }
`;
