import { Polygon as LeafletPolygon, Marker } from 'react-leaflet';
import L from 'leaflet';
import { FC } from 'react';

type PolygonProps = {
  onRemove: (id?: string) => void;
  points: [number, number][];
  color?: string;
};

export const Polygon: FC<PolygonProps> = ({ onRemove, points, color = 'orange' }) => {
  const textIcon = L.divIcon({
    className: 'custom-div-icon',
    html: `<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24">
	<g fill="none">
		<circle cx="12" cy="12" r="9" fill="${color}" />
		<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2" d="m16 8l-8 8m0-8l8 8" />
	</g>
</svg>`,
    iconSize: [20, 20]
  });
  return (
    <>
      <Marker
        position={points[0]}
        icon={textIcon}
        eventHandlers={{
          click: () => onRemove()
        }}
      />
      <LeafletPolygon
        key="new_polygon"
        positions={points}
        pathOptions={{ color, fillColor: color }}></LeafletPolygon>
    </>
  );
};
