import { ZoomUrlParamKey } from '@components/Map/ZoomHandler';
import { DEFAULT_ZOOM } from '@pages/Main/MapView/constants';
import { useSearchParams } from 'react-router-dom';

export const useZoomLevel = () => {
  const [params] = useSearchParams();
  const zoomFromUrl = params.get(ZoomUrlParamKey);
  const zoomLevel = zoomFromUrl ? Number(zoomFromUrl) : DEFAULT_ZOOM;
  return { zoomLevel };
};
