import type { Scan } from '@graphql/generated';
import { type Option, SELECT_ALL } from './MultiSelect';

export const getScanDatesOptions = (scans: Scan[]): Option[] => {
  const dateSet = new Set<string>(scans.map((scan) => scan.scan_date));

  dateSet.add(SELECT_ALL);

  const dates = Array.from(dateSet).map((date) => ({
    label: date,
    value: date
  }));

  return dates.sort((a, b) => {
    if (a.value === SELECT_ALL) return -1;
    if (b.value === SELECT_ALL) return 1;
    return new Date(a.value).getTime() - new Date(b.value).getTime();
  });
};
