import styled from 'styled-components';

export enum ButtonTypes {
  approve = 'approve',
  reject = 'reject'
}

const BackgroundColors = {
  [ButtonTypes.approve]: '#008744',
  [ButtonTypes.reject]: '#c137a2'
};

const getBackgroundColor = (type?: ButtonTypes): string => {
  if (!type) {
    return '#ffffff';
  }

  return BackgroundColors[type];
};

const TextColors = {
  [ButtonTypes.approve]: '#ffffff',
  [ButtonTypes.reject]: '#ffffff'
};

const getTextColor = (type?: ButtonTypes): string => {
  if (!type) {
    return 'none';
  }

  return TextColors[type];
};

export const Button = styled.button<{ type?: ButtonTypes }>`
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  border-radius: 25px;
  border: 1px solid rgba(0, 0, 0, 0.15);

  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1.25px;

  cursor: pointer;
  user-select: none;

  background-color: ${(props) => getBackgroundColor(props.type)};
  color: ${(props) => getTextColor(props.type)};

  &:hover {
    filter: brightness(85%);
  }

  &:disabled {
    filter: brightness(85%);
  }
`;
