import React from 'react';
import { useMapEvents } from 'react-leaflet';
import { useUrlParams } from '@hooks/useUrlParams';

export const ZoomUrlParamKey = 'zoom';

const ZoomHandler: React.FC = () => {
  const { setUrlParam } = useUrlParams();

  const setZoomToUrl = (zoom: number): void => setUrlParam(ZoomUrlParamKey, zoom);

  const mapEvents = useMapEvents({
    zoomend: () => {
      setZoomToUrl(mapEvents.getZoom());
    }
  });

  return null;
};

export default ZoomHandler;
