import { ApolloQueryResult, LazyQueryExecFunction, QueryResult } from '@apollo/client';

const checkErrors = <T>(queryResult: ApolloQueryResult<T>): void => {
  if (queryResult.error) {
    throw queryResult.error;
  }
  if (queryResult.errors) {
    throw queryResult.errors[0];
  }
};

export const reportAllPagesResults = async <T>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getData: LazyQueryExecFunction<T, any>,
  reporter: (res: QueryResult<T>) => void,
  getNextToken: (res: QueryResult<T>) => string | undefined | null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extraVars: any = {}
): Promise<void> => {
  let nextToken = null;
  do {
    const newRes = await getData({
      variables: { nextToken, ...extraVars }
    });
    checkErrors(newRes);
    reporter(newRes);
    nextToken = getNextToken(newRes);
  } while (nextToken);
};
