import styled from 'styled-components';

export const Container = styled.div`
  height: 48ּpx;
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
  padding: 12px;
  justify-content: end;
  align-items: center;
  display: flex;
`;

export const IconWrapper = styled.div`
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 50%;
    /* filter: brightness(55%); */
  }
`;

export const Text = styled.div`
  font-size: 14px;
  font-weight: 600;
`;

export const Icon = styled.img`
  filter: brightness(0);
  height: 24px;
  cursor: pointer;
  color: black;
`;
