import styled from 'styled-components';

export const MapButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow-x: auto;
  gap: 2px;
  padding: 2px 0;
`;

export const Text = styled.div`
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  white-space: nowrap;
  &.disabled {
    color: #ddd;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
