export const getFormattedDate = (date: Date): string => {
  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`;
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
};

export const getDatePlusDays = (date: Date, days: number): string => {
  date.setDate(date.getDate() + days);

  return getFormattedDate(date);
};
