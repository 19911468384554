import { OtherUtilityData } from '@components/Map/OtherUtilities';
import { AnalyzedEntity } from '@contexts/AnalyzedEntityContext';

export const useOtherUtilitiesSameFrequency = (
  analyzedEntity: AnalyzedEntity | undefined,
  otherUtilitiesData: OtherUtilityData[]
) => {
  return otherUtilitiesData.filter((item) => {
    return item?.frequency === analyzedEntity?.frequency;
  });
};
