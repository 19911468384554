import React from 'react';
import { Label } from './styles';

interface Props {
  checked?: boolean;
  onChange?: (value: boolean) => void;
  label?: string;
  className?: string;
}

const Checkbox: React.FC<Props> = (props) => {
  const { checked, onChange, label, className } = props;

  const onClick = (): void => {
    onChange?.(!checked);
  };

  return (
    <Label className={className}>
      <input type="checkbox" checked={checked} onChange={onClick} />
      {label}
    </Label>
  );
};

export default Checkbox;
