import { Scan, useGetScansByFilterLazyQuery } from '@graphql/generated';
import { Filter, getQueryOptions } from '@helpers/filterData';
import { useState } from 'react';
import { reportAllPagesResults } from '@helpers/reportAllPagesResults';

interface Result {
  scans: Scan[];
  getScans: (filter: Filter) => Promise<void>;
  countries: string[];
  loading: boolean;
}

export const useAllScansByFilterWithCountriesQuery = (): Result => {
  const [scans, setScans] = useState<Scan[]>([]);
  const [getData, data] = useGetScansByFilterLazyQuery({ fetchPolicy: 'network-only' });
  const [loading, setLoading] = useState(false);

  const getDataByFilters = async (filter: Filter): Promise<void> => {
    setScans([]);
    setLoading(true);
    await reportAllPagesResults(
      getData,
      (res) => setScans((scans) => [...scans, ...((res.data?.searchScans?.items ?? []) as Scan[])]),
      (res) => res.data?.searchScans?.nextToken,
      getQueryOptions(filter).variables
    ).finally(() => {
      setLoading(false);
    });
  };

  const countries: string[] =
    data.data?.searchScans?.aggregateItems
      .find((item) => item?.name === 'country_name')
      // @ts-expect-error
      ?.result?.buckets.map((item: { key: string }) => item.key) || [];

  return {
    scans,
    getScans: getDataByFilters,
    countries,
    loading
  };
};
