import React from 'react';
import { GeoJsonObject } from 'geojson';
import GeoJsonWithUpdates from '@components/Map/GeoJsonWithUpdates';
import { GeoJSONOptions } from 'leaflet';
import { currentUtilityColor } from '@constants/colors';

interface Props {
  utilityData?: GeoJsonObject;
}

const styles: GeoJSONOptions['style'] = {
  color: currentUtilityColor
};

const Utility: React.FC<Props> = (props) => {
  const { utilityData } = props;

  if (utilityData === undefined) {
    return null;
  }

  return <GeoJsonWithUpdates data={utilityData} style={styles} />;
};

export default Utility;
