import React, { useContext } from 'react';
import {
  SubTitleText,
  TitleContainer,
  TitleText,
  StyledButton,
  HeaderContainer,
  NameContainer,
  Divider,
  IDTitle
} from './styles';
import AnalyzedEntityContext from '@contexts/AnalyzedEntityContext';

interface Props {
  onClickCenter: () => void;
}

const Header: React.FC<Props> = (props) => {
  const { onClickCenter } = props;
  const { analyzedEntity } = useContext(AnalyzedEntityContext);

  return (
    <HeaderContainer>
      <TitleContainer>
        <NameContainer>
          <TitleText>{analyzedEntity?.scan?.scan_date}</TitleText>
          <Divider />
          <TitleText>{analyzedEntity?.scan?.site_name}</TitleText>
          <Divider />
          <SubTitleText>{analyzedEntity?.scan?.scan_name}</SubTitleText>
        </NameContainer>
        <StyledButton onClick={onClickCenter}>Center</StyledButton>
      </TitleContainer>
      <IDTitle>UUID: {analyzedEntity?.id}</IDTitle>
    </HeaderContainer>
  );
};

export default Header;
