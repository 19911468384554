import { LatLngExpression } from 'leaflet';
import { XMarker } from './XMarker';

type YPeaksProps = {
  data: any[];
};

export const YPeaks = ({ data }: YPeaksProps) => {
  return (
    <>
      {data.map((position, i) => {
        const [lng, lat] = position;
        return <XMarker color="orange" key={`${lat}_${lng}`} position={[lat, lng]} size={20} />;
      })}
    </>
  );
};
