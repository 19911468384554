import Select from 'react-select';
import { useCallback, useEffect, useMemo, useState } from 'react';

import type { Scan, Site } from '@graphql/generated';
import { useEffectAsync } from '@hooks/useEffectAsync';
import { useAllSitesQuery } from '@hooks/prefetch/usePrefetchAllSites';
import { useAllScansQuery } from '@hooks/prefetch/usePrefetchAllScans';
import { useGetPlatfromTypesQuery } from '@hooks/prefetch/usePrefechPlatfromTypes';
import { LoadingSpinner } from '@components/LoaderSpinner';
import { MultiSelect, SELECT_ALL } from './MultiSelect';
import { SearchContainer } from './styles';
import { ConfidenceOptions, SiteStatusOptions, CountSearch, unifyOptions } from './constans';
import { createLinks } from './createLink';
import { getScanNamesOptions } from './getScanNamesOptions';
import { getScanDatesOptions } from './getScanDatesOptions';
import { getFrequencyOptions } from './getFrequencyOptions';
import { getPlatformTypeOptions } from './getPlatformTypeOptions';
import { getMethodOptions } from './getMethodOptions';

const Search = () => {
  const [selectedSite, setSelectedSite] = useState<Site | null>();
  const [selectedConfidence, setSelectedConfidence] = useState<string[]>([SELECT_ALL]);
  const [selectedSiteStatus, setSiteSelectedStatus] = useState<string[]>(['PENDING']);
  const [selectedUnify, setSelectedUnify] = useState<string[]>([SELECT_ALL]);
  const [selectedPlatformType, setSelectedPlatformType] = useState<string[]>([SELECT_ALL]);
  const [selectedScanDates, setSelectedScanDates] = useState<Scan[]>([]);
  const [selectedDate, setSelectedDates] = useState<string[]>([]);
  const [selectedFrequencies, setSelectedFrequencies] = useState<string[]>([]);
  const [selectedMethods, setSelectedMethods] = useState<string[]>([]);
  const [links, setLinks] = useState<string[]>([]);
  const [count, setCount] = useState(0);
  const [selectedMaxValue, setSelectedMaxValue] = useState(50);

  const { sites, getSites, loading } = useAllSitesQuery();
  const { platfromTypes, loading: loadPlatforms } = useGetPlatfromTypesQuery(
    selectedSite?.id || ''
  );
  const {
    scans,
    getScans,
    loading: loadScan
  } = useAllScansQuery(
    selectedSite?.id || '',
    selectedConfidence.filter((c) => c !== SELECT_ALL),
    selectedSiteStatus,
    selectedUnify,
    selectedPlatformType
  );
  const platfromTypeOptions = useMemo(() => getPlatformTypeOptions(platfromTypes), [platfromTypes]);
  const scanDateOptions = useMemo(
    () => getScanDatesOptions(selectedScanDates),
    [selectedScanDates]
  );
  const frequencyOptions = useMemo(
    () => getFrequencyOptions(scans, selectedScanDates),
    [scans, selectedScanDates]
  );
  const methodOptions = useMemo(() => getMethodOptions(scans), [scans]);
  const scanNameOptions = useMemo(() => getScanNamesOptions(scans), [scans]);

  const onScanNameChange = useCallback(
    (value: string[]) => {
      const newSelectedScanDates = scans.filter((s) => value.includes(s.scan_name));

      setSelectedScanDates(newSelectedScanDates);
    },
    [scans]
  );
  const onScanDateChange = useCallback((value: string[]) => {
    return setSelectedDates(value);
  }, []);
  const onFrequencyChange = useCallback((value: string[]) => setSelectedFrequencies(value), []);
  const onMethodChange = useCallback((value: string[]) => setSelectedMethods(value), []);

  const onConfidenceChange = useCallback((value: string[]) => {
    if (value.includes(SELECT_ALL)) {
      setSelectedConfidence(
        ConfidenceOptions.filter((c) => c.value !== SELECT_ALL).map((c) => c.value)
      );
    } else {
      setSelectedConfidence(value);
    }
  }, []);

  const onSiteStatusChange = useCallback((value: string[]) => {
    setSiteSelectedStatus(value);
  }, []);

  const onUnifyChange = useCallback((value: string[]) => {
    if (value.includes(SELECT_ALL)) {
      setSelectedUnify(unifyOptions.filter((c) => c.value !== SELECT_ALL).map((c) => c.value));
    } else {
      setSelectedUnify(value);
    }
  }, []);
  const onPlatformTypeChange = useCallback((value: string[]) => {
    if (value.includes(SELECT_ALL)) {
      setSelectedPlatformType(
        platfromTypeOptions.filter((c) => c.value !== SELECT_ALL).map((c) => c.value)
      );
    } else {
      setSelectedPlatformType(value);
    }
  }, []);

  useEffectAsync(async () => {
    setCount(0);
    setLinks([]);

    await getScans();
  }, [selectedConfidence, selectedSiteStatus, selectedSite, selectedUnify, selectedPlatformType]);

  useEffect(() => {
    if (!selectedScanDates.length) {
      setLinks([]);
      setCount(0);
      return;
    }
    const { links: newLinks, count } = createLinks({
      selectedScanDates,
      selectedMaxValue,
      selectedDate,
      selectedFrequencies,
      selectedMethods,
      selectedSiteStatus
    });

    setCount(count);
    setLinks(newLinks);
  }, [
    selectedScanDates,
    selectedMaxValue,
    selectedDate,
    selectedFrequencies,
    selectedMethods,
    selectedSiteStatus
  ]);

  useEffectAsync(async () => {
    await getSites();
  }, []);

  return (
    <SearchContainer>
      <h1>PipesQA - Search Page</h1>
      <>
        <Select
          isLoading={loading}
          id="site-select"
          onChange={(newVal) => setSelectedSite(newVal as Site)}
          value={sites.find((opt) => opt.id === selectedSite?.id)}
          isSearchable
          isClearable
          placeholder="Select Site...."
          options={sites}
          getOptionLabel={(opt) => opt.name}
          getOptionValue={(opt) => opt.id}
        />
        {!!sites.length && (
          <>
            <MultiSelect
              label="Confidence"
              placeholder="Select Confidence..."
              options={ConfidenceOptions}
              defaultValue={ConfidenceOptions.filter((s) => s.value !== SELECT_ALL)}
              onChange={onConfidenceChange}
            />
            <MultiSelect
              label="Status"
              placeholder="Select Site Status..."
              options={SiteStatusOptions}
              defaultValue={SiteStatusOptions.filter((s) => s.value !== SELECT_ALL)}
              onChange={onSiteStatusChange}
            />
            <MultiSelect
              label="Is Unified"
              placeholder="Unify options..."
              options={unifyOptions}
              defaultValue={unifyOptions.filter((s) => s.value !== SELECT_ALL)}
              onChange={onUnifyChange}
            />
            {loadPlatforms && selectedSite?.id ? (
              <span>Loading platform types...</span>
            ) : selectedSite?.id ? (
              <MultiSelect
                label="Platform Types"
                placeholder="Select Platform Types..."
                options={platfromTypeOptions}
                isLoading={loadPlatforms}
                defaultValue={platfromTypeOptions.filter((s) => s.value !== SELECT_ALL)}
                onChange={onPlatformTypeChange}
              />
            ) : (
              <></>
            )}
          </>
        )}
        {loadScan ? (
          <LoadingSpinner />
        ) : scans.length > 0 ? (
          <>
            <hr
              style={{
                width: '100%',
                borderColor: 'rgba(0,0,0,0.2)'
              }}
            />
            <MultiSelect
              label="Scans"
              placeholder="Select Scans..."
              isLoading={loadScan}
              options={scanNameOptions}
              onChange={onScanNameChange}
            />
            <MultiSelect
              label="Dates"
              placeholder="Select Dates..."
              options={scanDateOptions}
              onChange={onScanDateChange}
              isLoading={loadScan}
            />
            <MultiSelect
              label="Method"
              placeholder="Select Method..."
              options={methodOptions}
              onChange={onMethodChange}
              isLoading={loadScan}
            />
            <MultiSelect
              label="Frequencies"
              placeholder="Select Frequencies..."
              options={frequencyOptions}
              onChange={onFrequencyChange}
              isLoading={loadScan}
            />
            <div>
              <label htmlFor="max-value-select">Entities per link</label>
              <Select
                id="max-value-select"
                value={CountSearch.find((opt) => opt.value === selectedMaxValue)}
                onChange={(newVal) => setSelectedMaxValue(newVal?.value || 50)}
                options={CountSearch}
                isSearchable
                isClearable
                placeholder="Select max value..."
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: 200
                  })
                }}
              />
              {count ? <h2>Items: {count}</h2> : null}
              <h2>Links to PipesQA:</h2>
              <div style={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', gap: '16px' }}>
                {links.map((link, index) => (
                  <div key={link} style={{ paddingBottom: '16px' }}>
                    <a key={link} href={link} target="_blank" rel="noreferrer">
                      PipesQA Link{index + 1}
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </>
        ) : (
          <div style={{ display: 'flex', alignItems: 'center', fontWeight: 500, fontSize: 18 }}>
            No Data
          </div>
        )}
      </>
    </SearchContainer>
  );
};

export default Search;
