import React from 'react';
import AuthorizedTileLayer from '../AuthorizedTileLayer';
import { orthophotoLayerConfig } from '@constants/mapConfigs';

const Orthophoto: React.FC = () => {
  return (
    <AuthorizedTileLayer
      url={process.env.REACT_APP_ORTHOPHOTO_URL ?? ''}
      maxNativeZoom={orthophotoLayerConfig.maxNativeZoom}
      maxZoom={orthophotoLayerConfig.maxZoom}
    />
  );
};

export default Orthophoto;
