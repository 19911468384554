import styled from 'styled-components';

export const Container = styled.div`
  background: #ffffff;
  width: 400px;
  height: 600px;
  border-radius: 8px;
  position: absolute;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 1px 10px 0 rgba(0, 0, 0, 0.09),
    0 4px 5px 0 rgba(0, 0, 0, 0.11);

  flex-direction: column;
  justify-content: space-between;
  display: flex;
`;
