import styled from 'styled-components';

export const Container = styled.div`
  min-width: 500px;
`;

export const Text = styled.div`
  font-size: 24px;
  line-height: 150%;
`;
