import { FC, PropsWithChildren } from 'react';

type ControlButtonProps = PropsWithChildren<{
  onClick: () => void;
  isSelected?: boolean;
}>;

export const ControlButton: FC<ControlButtonProps> = ({ onClick, children, isSelected }) => {
  return (
    <button
      onClick={onClick}
      style={{
        cursor: 'pointer',
        backgroundColor: isSelected ? 'rgba(0,0,0,0.75)' : 'white',
        border: '.5px solid rgba(0,0,0,.5)',
        color: isSelected ? 'white' : 'black',
        borderRadius: '4px',
        padding: 3,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: isSelected ? '0 3px 6px rgba(0,0,0,0.5)' : 'none',
        transition: 'all ease 300ms'
      }}>
      {children}
    </button>
  );
};
