import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  min-width: 300px;
`;

export const Input = styled.input`
  border-radius: 4px;
  border: 1px solid #000;
  padding: 15px 10px 0 10px;
  width: 100%;
  height: 36px;

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
`;

export const Label = styled.label`
  font-size: 16px;
  font-weight: 300;
  position: absolute;
  left: 10px;
`;
