import React from 'react';
import { Container, Input, Label } from './styles';

interface Props {
  id: string;
  label?: string;
  value: string;
  onChange: (value: string) => void;
  className?: string;
}

const DateInput: React.FC<Props> = (props) => {
  const { value, onChange, id, label, className } = props;

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
    onChange(e.target.value);
  };

  return (
    <Container className={className}>
      {label && <Label htmlFor={id}>{label}</Label>}
      <Input type={'date'} id={id} value={value} onChange={onChangeInput} />
    </Container>
  );
};

export default DateInput;
