import * as msal from '@azure/msal-browser';

export type userData = msal.AuthenticationResult;

export interface RequestParams {
  baseUrl: string | undefined;
  headers: { authorization: string };
}

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_SSO_CLIENT_ID ?? '',
    authority: process.env.REACT_APP_SSO_AUTHORITY ?? '',
    redirectUri: process.env.REACT_APP_SSO_REDIRECT_URI ?? ''
  }
};

export const isExpired = (user?: userData): boolean => {
  return (
    user === undefined ||
    user.expiresOn === null ||
    user.expiresOn.getUTCMilliseconds() > Date.now()
  );
};

export const logIn = async (): Promise<userData | undefined> => {
  if (process.env.REACT_APP_SSO_SCOPES === undefined) {
    throw new Error('Env variables are not set for log in');
  }
  const loginRequest = {
    scopes: process.env.REACT_APP_SSO_SCOPES.split(',')
  };

  const msalInstance = new msal.PublicClientApplication(msalConfig);
  const res = await msalInstance.handleRedirectPromise();

  if (res !== null) {
    return res;
  }

  await msalInstance.loginRedirect(loginRequest);
};

export const logOut = async (user: userData): Promise<void> => {
  const msalInstance = new msal.PublicClientApplication(msalConfig);

  if (user.account === null || user.account.homeAccountId === undefined) {
    throw new Error('User account property was malformed');
  }

  const homeAccountId = user.account.homeAccountId;
  const logoutRequest = {
    account: msalInstance.getAccountByHomeId(homeAccountId),
    mainWindowRedirectUri: process.env.REACT_APP_SSO_REDIRECT_URI
  };

  await msalInstance.logoutPopup(logoutRequest);
};

export async function getBearerToken(): Promise<string> {
  if (process.env.REACT_APP_SSO_SCOPES === undefined) {
    throw new Error('Env variables are not set for log in');
  }

  const msalInstance = new msal.PublicClientApplication(msalConfig);
  const redirectResponse = await msalInstance.handleRedirectPromise();
  if (redirectResponse !== null) {
    return `bearer ${redirectResponse.accessToken}`;
  } else {
    const account = msalInstance.getAllAccounts()[0];
    const accessTokenRequest = {
      scopes: process.env.REACT_APP_SSO_SCOPES.split(','),
      account
    };

    let token;
    try {
      token = (await msalInstance.acquireTokenSilent(accessTokenRequest)).accessToken;
    } catch (error) {
      if (error instanceof msal.InteractionRequiredAuthError) {
        await msalInstance.acquireTokenRedirect(accessTokenRequest);
      }
    }
    return `bearer ${token ?? ''}`;
  }
}

export async function getRequestParamsOnlyHeaders(): Promise<Pick<RequestParams, 'headers'>> {
  const bearToken = await getBearerToken();

  return {
    headers: {
      authorization: bearToken
    }
  };
}

export async function getRequestParams(): Promise<RequestParams> {
  const bearToken = await getBearerToken();

  return {
    baseUrl: process.env.REACT_APP_BACKEND_HOST,
    headers: {
      authorization: bearToken
    }
  };
}
