import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;

export const MapWrapper = styled.div`
  position: relative;
  height: 95%;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
`;
