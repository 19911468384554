import React from 'react';
import { Container } from './styles';
import { Status as StatusEnum } from '@graphql/generated';

interface Props {
  status?: StatusEnum;
}

const Status: React.FC<Props> = (props) => {
  const { status } = props;

  switch (true) {
    case status === StatusEnum.Approved:
      return <Container type={StatusEnum.Approved}>Approved</Container>;
    case status === StatusEnum.Test:
      return <Container type={StatusEnum.Test}>Testing</Container>;
    case status === StatusEnum.Pending:
      return <Container type={StatusEnum.Pending}>Pending</Container>;
    case status === StatusEnum.Rejected:
      return <Container type={StatusEnum.Rejected}>Rejected</Container>;
    default:
      return <Container type={StatusEnum.Pending}>Loading</Container>;
  }
};

export default Status;
