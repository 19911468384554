import { SELECT_ALL } from './MultiSelect';

interface CountOption {
  value: number;
  label: string;
}
export const UNIFY = 'UNIFIED';
export const NON_UNIFY = 'NON_UNIFIED';
export const UNIFIED = 'unified';

export const CountSearch: readonly CountOption[] = [
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
  { value: 200, label: '200' }
];

export const ConfidenceOptions = [
  { value: SELECT_ALL, label: SELECT_ALL },
  { value: 'HIGHEST', label: 'Highest' },
  { value: 'HIGH', label: 'High' },
  { value: 'MEDIUM', label: 'Medium' },
  { value: 'LOW', label: 'Low' },
  { value: 'LOWEST', label: 'Lowest' },
  { value: 'NONE', label: 'None' }
];

export const SiteStatusOptions = [
  { value: SELECT_ALL, label: SELECT_ALL },
  { value: 'APPROVED', label: 'Approved' },
  { value: 'PENDING', label: 'Pending' },
  { value: 'REJECTED', label: 'Rejected' },
  { value: 'NO_VALIDATION_REQUIRED', label: 'No Validation Required' }
];

export const unifyOptions = [
  { value: SELECT_ALL, label: SELECT_ALL },
  { value: UNIFY, label: 'Unified' },
  { value: NON_UNIFY, label: 'Non Unified' }
];
