import { create } from 'zustand';

const MIN_SLIDER_VALUE = 0;
const MAX_SLIDER_VALUE = 100;

type MapControlsStore = {
  maxZoom: number;
  minZoom: number;
  setMaxZoom: (value: number) => void;
  setMinZoom: (value: number) => void;
};

export const useMapControlsStore = create<MapControlsStore>((set) => ({
  maxZoom: MAX_SLIDER_VALUE,
  minZoom: MIN_SLIDER_VALUE,
  setMaxZoom: (value: number) => set(() => ({ maxZoom: value })),
  setMinZoom: (value: number) => set(() => ({ minZoom: value }))
}));
