import React from 'react';
import { Container, DateContainer, Email, Row, Text, DeleteButton, DeleteIcon } from './styles';
import { Comment as CommentGeneratedType } from '@graphql/generated';
import { format } from 'date-fns';
import DeleteSVG from '@assets/delete.svg';

export type CommentType = Pick<CommentGeneratedType, 'id' | 'createdAt' | 'changed_by' | 'text'>;

interface Props {
  comment: CommentType;
  onClickComment: (comment: CommentType) => void;
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const Comment: React.FC<Props> = (props) => {
  const { comment, onClickComment, setIsOpenModal } = props;
  return (
    <Container>
      <Row>
        <Email>{comment.changed_by}</Email>
        <DateContainer>{format(comment.createdAt, 'dd/MM/yyyy HH:mm')}</DateContainer>
      </Row>
      <Text
        onClick={() => {
          onClickComment(comment);
        }}>
        {comment.text}
        <DeleteButton onClick={() => setIsOpenModal(true)}>
          <DeleteIcon src={DeleteSVG} />
        </DeleteButton>
      </Text>
    </Container>
  );
};

export default Comment;
