import type { Scan } from '@graphql/generated';
import { type Option } from './MultiSelect';

export const getFrequencyOptions = (scans: Scan[], selectedScanDates: Scan[]): Option[] => {
  const frequencySet = new Set<string>();

  scans.forEach((scan) => {
    if (
      selectedScanDates.length === 0 ||
      selectedScanDates.some((selectedScan) => selectedScan.id === scan.id)
    ) {
      scan?.analyzed_entities?.items.forEach((entity) => {
        if (entity?.frequency) {
          frequencySet.add(entity.frequency.toString());
        }
      });
    }
  });

  return Array.from(frequencySet).map((frequency) => ({
    value: frequency,
    label: frequency
  }));
};
