import styled from 'styled-components';

export const Container = styled.label`
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  min-width: 25px;
  min-height: 25px;
`;

export const CheckboxInput = styled.input``;

export const Checkmark = styled.span<{ checked?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: ${(props) => (props.checked ? 'rgba(105, 78, 214, 1)' : '#eee')};

  &:hover {
    filter: brightness(85%);
  }

  &:after {
    content: '';
    position: absolute;
    display: ${(props) => (props.checked ? 'block' : 'none')};

    left: 6px;
    top: -2px;
    width: 10px;
    height: 18px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
