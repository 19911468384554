import React, { ReactNode } from 'react';
import Select, { MultiValue } from 'react-select';

export interface Option {
  value: string;
  label: string;
}

interface Props {
  selectedOptions: Option[];
  setOptions: (options: readonly Option[]) => void;
  options: Option[];
  placeholder?: ReactNode;
}

const MultiSelectInput: React.FC<Props> = (props) => {
  const { selectedOptions, setOptions, options, placeholder } = props;

  const onChange = (newValue: MultiValue<Option>): void => {
    setOptions(newValue);
  };

  return (
    <Select
      onChange={onChange}
      value={selectedOptions}
      isMulti={true}
      options={options}
      placeholder={placeholder}
      styles={{
        container: (base) => ({
          ...base,
          minWidth: '300px'
        })
      }}
    />
  );
};

export default MultiSelectInput;
