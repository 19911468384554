import { useEffect, DependencyList } from 'react';

export const useEffectAsync = (effect: Function, inputs: DependencyList): void => {
  useEffect(() => {
    const cleanup = effect();
    return () => {
      if (cleanup instanceof Function) {
        cleanup();
      }
    };
  }, inputs);
};
