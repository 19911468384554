import { useGetAnalyzedEntityByIdLazyQuery } from '@graphql/generated';
import { useContext, useEffect, useState } from 'react';
import { useEffectAsync } from '@hooks/useEffectAsync';
import AnalyzedEntityContext, { AnalyzedEntity } from '@contexts/AnalyzedEntityContext';

import { useCurrentDepth } from '@hooks/prefetch/useCurrentDepth';
import { getSortedAnalyzedEntities } from '@helpers/filterData';

interface Props {
  currentId: string;
  ids: string[];
  isListLoading: boolean;
}

export const useAnalyzedEntity = (props: Props): void => {
  const { currentId, ids, isListLoading } = props;
  const [getData, { data }] = useGetAnalyzedEntityByIdLazyQuery();
  const [getDataForList] = useGetAnalyzedEntityByIdLazyQuery();
  const {
    setAnalyzedEntity,
    setAnalyzedEntityList,
    analyzedEntityList,
    setEmptyAnalyzedEntitiesIdList
  } = useContext(AnalyzedEntityContext);

  useCurrentDepth();
  useEffectAsync(async () => {
    if (!currentId) {
      return;
    }

    const fetchedAnalyzedEntity = analyzedEntityList.find(
      (item) => item?.id && item.id === currentId
    );

    if (fetchedAnalyzedEntity) {
      setAnalyzedEntity(fetchedAnalyzedEntity);
      if (ids.length === 0) {
        setAnalyzedEntityList([fetchedAnalyzedEntity]);
      }
      return;
    }

    await getData({
      variables: {
        id: currentId
      }
    });
  }, [currentId]);

  useEffectAsync(async () => {
    if (!data?.getAnalyzedEntity?.id) {
      return;
    }

    setAnalyzedEntity(data.getAnalyzedEntity);
    if (ids.length === 0) {
      setAnalyzedEntityList([data.getAnalyzedEntity]);
    }
  }, [data?.getAnalyzedEntity?.id]);

  useEffectAsync(async () => {
    if (isListLoading) {
      return;
    }

    if (ids.length === analyzedEntityList.length) {
      return;
    }
    void Promise.all(
      ids.map(async (id) => {
        return {
          data: await getDataForList({
            variables: {
              id
            }
          }),
          id
        };
      })
    ).then((result) => {
      const emptyList: string[] = [];
      if (result.length === 0) {
        return;
      }
      result.forEach((item) => {
        if (!item.data?.data?.getAnalyzedEntity) {
          emptyList.push(item.id);
        }
      });
      setAnalyzedEntityList(
        getSortedAnalyzedEntities(
          result
            .map((item) => item.data?.data?.getAnalyzedEntity)
            .filter((item) => !!item) as AnalyzedEntity[]
        )
      );
      setEmptyAnalyzedEntitiesIdList(Array.from(new Set(emptyList)));
    });
  }, [isListLoading]);
};
