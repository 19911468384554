import React from 'react';
import {
  CheckboxWithMargin,
  DateInputWithMargin,
  DropdownInputWithMargin,
  Container,
  InputContainer
} from './styles';
import { Filter } from '@helpers/filterData';
import MultiSelectInput, { Option } from '@components/MultiSelectInput';
import { Confidence } from '@graphql/generated';

interface Props {
  filter: Filter;
  setFilter: (value: Filter) => void;
  countryOptions: string[];
  siteOptions: string[];
  scanOptions: string[];
  utilityIdOptions: string[];
  confidenceOptions: Confidence[];
  frequencyOptions: string[];
}

const FilterInputs: React.FC<Props> = (props) => {
  const {
    filter,
    setFilter,
    countryOptions,
    siteOptions,
    scanOptions,
    utilityIdOptions,
    frequencyOptions,
    confidenceOptions
  } = props;

  const setNewFilter = <T extends keyof Filter>(key: T, value: Filter[T]): void => {
    setFilter({
      ...filter,
      [key]: value
    });
  };

  return (
    <Container>
      <DropdownInputWithMargin
        options={countryOptions}
        value={filter.country}
        onChange={(value) => setNewFilter('country', value)}
        id={'country'}
        label={'Country'}
      />
      <DropdownInputWithMargin
        options={[]}
        value={filter.project}
        onChange={(value) => setNewFilter('project', value)}
        id={'project'}
        label={'Project'}
      />
      <DropdownInputWithMargin
        options={siteOptions}
        value={filter.site}
        onChange={(value) => setNewFilter('site', value)}
        id={'site'}
        label={'Site'}
      />
      <DropdownInputWithMargin
        options={[]}
        value={filter.domain}
        onChange={(value) => setNewFilter('domain', value)}
        id={'domain'}
        label={'Domain'}
      />
      <DropdownInputWithMargin
        options={scanOptions}
        value={filter.scanName}
        onChange={(value) => setNewFilter('scanName', value)}
        id={'scan'}
        label={'Scan'}
      />
      <DateInputWithMargin
        value={filter.startDate}
        onChange={(value) => setNewFilter('startDate', value)}
        id={'start-date'}
        label={'Start Date'}
      />
      <DateInputWithMargin
        value={filter.endDate}
        onChange={(value) => setNewFilter('endDate', value)}
        id={'end-date'}
        label={'End Date'}
      />

      <InputContainer>
        <MultiSelectInput
          selectedOptions={filter.confidences.map((item) => {
            return {
              label: item,
              value: item
            };
          })}
          setOptions={(value: readonly Option[]) =>
            setNewFilter(
              'confidences',
              value.map((item) => item.value as Confidence)
            )
          }
          options={confidenceOptions.map((item) => {
            return {
              label: item,
              value: item
            };
          })}
          placeholder={'Confidence'}
        />
      </InputContainer>

      <InputContainer>
        <MultiSelectInput
          selectedOptions={filter.frequencies.map((item) => {
            return {
              label: item,
              value: item
            };
          })}
          setOptions={(value: readonly Option[]) =>
            setNewFilter(
              'frequencies',
              value.map((item) => item.value)
            )
          }
          options={frequencyOptions.map((item) => {
            return {
              label: item,
              value: item
            };
          })}
          placeholder={'Frequency'}
        />
      </InputContainer>

      <InputContainer>
        <MultiSelectInput
          selectedOptions={filter.analyzedEntitiesIds.map((item) => {
            return {
              label: item,
              value: item
            };
          })}
          setOptions={(value: readonly Option[]) =>
            setNewFilter(
              'analyzedEntitiesIds',
              value.map((item) => item.value)
            )
          }
          options={utilityIdOptions.map((item) => {
            return {
              label: item,
              value: item
            };
          })}
          placeholder={'Utility ID(s)'}
        />
      </InputContainer>
      <CheckboxWithMargin
        onChange={(value) => setNewFilter('analyzedEntityFlagged', value)}
        checked={filter.analyzedEntityFlagged}
        label={'Only Flagged'}
      />
    </Container>
  );
};

export default FilterInputs;
