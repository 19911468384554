import React, { createContext, useState } from 'react';

const SortContext = createContext<{
  sortBy: SortBy | undefined;
  setSortBy: (sortBy: SortBy | undefined) => void;
}>({
  sortBy: undefined,
  setSortBy: (sortBy: SortBy | undefined) => {}
});

interface Props {
  children: React.ReactNode;
}

export enum SortBy {
  confidence = 'confidence',
  scan = 'scan',
  frequency = 'frequency'
}

export const SortProvider: React.FC<Props> = (props) => {
  const { children } = props;
  const [sortBy, setSortBy] = useState<SortBy>();

  return (
    <SortContext.Provider
      value={{
        setSortBy,
        sortBy
      }}>
      {children}
    </SortContext.Provider>
  );
};

export default SortContext;
