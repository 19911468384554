import { InterpolationData } from '@components/Map';
import { create } from 'zustand';

type InterpolationDataStore = {
  interpolationData: Record<string, InterpolationData>;
  setInterpolationData: (url: string, interpolationData: InterpolationData) => void;
  getInterpolation: (url: string) => InterpolationData;
};

export const useInterpolationDataStore = create<InterpolationDataStore>((set, get) => ({
  interpolationData: {},
  setInterpolationData: (url, interpolationData) =>
    set((state) => ({
      interpolationData: {
        ...state.interpolationData,
        [url]: interpolationData
      }
    })),
  getInterpolation: (url) => get().interpolationData[url]
}));
