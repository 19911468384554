import { FC } from 'react';
import { Polygon } from './Polygon';
import { PolygonKeys } from '@store';

type PolygonListProps = {
  polygons: Partial<Record<PolygonKeys, Record<string, [number, number][]>>>;
  onRemove: (id: string, key: PolygonKeys) => void;
};

export const PolygonList: FC<PolygonListProps> = ({ polygons, onRemove }) => {
  return (
    <>
      {Object.entries(polygons).map(([key, polygon]) => {
        return Object.entries(polygon).map(([id, points]) => {
          return (
            <Polygon key={id} onRemove={() => onRemove(id, key as PolygonKeys)} points={points} />
          );
        });
      })}
    </>
  );
};
