export const ProgressLoader = ({ progress }: { progress: number }) => {
  const radius = 35;
  const stroke = 4;
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <div
      style={{
        background: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: radius * 2,
        width: radius * 2,
        boxShadow: '0 3px 8px rgba(0,0,0,0.3)',
        borderRadius: '50%'
      }}>
      <svg height={radius * 2} width={radius * 2} viewBox={`0 0 ${radius * 2} ${radius * 2}`}>
        <circle
          stroke="#F0F8FF"
          fill="transparent"
          strokeWidth={stroke}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
          style={{ borderRadius: 16 }}
        />
        <circle
          stroke="blue"
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={circumference + ' ' + circumference}
          style={{ strokeDashoffset, borderRadius: 16 }}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
          strokeLinecap="round"
          transform={`rotate(-90 ${radius} ${radius})`}
        />
        <text
          x="50%"
          y="50%"
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize="1qpx"
          fontWeight="bold"
          fill="black">
          {`${progress}%`}
        </text>
      </svg>
    </div>
  );
};
